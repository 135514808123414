import Api from "@/services/Api";

export default {
  namespaced: true,
  state: {
    settings: {},
    certificates: {},
    certificate: {}
  },
  getters: {
    getSettings: (state: any) => state.settings,
    getCertificates: (state: any) => state.certificates,
    getCertificate: (state: any) => state.certificate
  },
  mutations: {
    SET_CERTIFICATES: (state: any, data: any) => {
      state.certificates = data;
    },

    SET_SETTINGS: (state: any, data: any) => {
      state.settings = data;
    },
    SET_CERTIFICATE: (state: any, data: any) => {
      state.certificate = data;
    }
  },
  actions: {
    GET_CERTIFICATES: ({ commit }: { commit: any }, data: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .post(`certificates`, data)
          .then(({ data }) => {
            commit("SET_CERTIFICATES", data.data);
            resolve(data.data);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    GET_CERTIFICATE: ({ commit }: { commit: any }, data: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .get(`session/${data.id}/certificate`)
          .then(({ data }) => {
            commit("SET_CERTIFICATE", data.data);
            resolve(data.data);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    GET_SETTINGS: ({ commit }: { commit: any }) => {
      return new Promise((resolve, reject) => {
        Api()
          .get(`certificate-settings`)
          .then(({ data }) => {
            commit("SET_SETTINGS", data.data);
            resolve(data.data);
          })
          .catch(e => {
            reject(e);
          });
      });
    }
  }
};
