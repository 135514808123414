import Api from "@/services/Api";

export default {
  namespaced: true,
  state: {
    ratings: {},
    rating_types: {}
  },
  getters: {
    getRatings: (state: any) => state.ratings,
    getRatingTypes: (state: any) => state.rating_types
  },
  mutations: {
    SET_RATINGS: (state: any, data: any) => {
      state.ratings = data;
    },
    SET_RATING_TYPES: (state: any, data: any) => {
      state.rating_types = data;
    }
  },
  actions: {
    GET_RATINGS: ({ commit }: { commit: any }, data: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .get(
            `rating/${data.type}/${data.id}?page=${data.page}&tab=${data?.tab}`
          )
          .then(({ data }) => {
            commit("SET_RATINGS", data.data);
            resolve(data.data);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    GET_RATING_TYPES: ({ commit }: { commit: any }, data: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .get(`rating/type?type=${data.type}`)
          .then(({ data }) => {
            commit("SET_RATING_TYPES", data.data);
            resolve(true);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    CREATE_RATING: (_: any, data: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .post(`rating/${data.type}`, data.data)
          .then(() => {
            resolve(true);
          })
          .catch(e => {
            reject(e);
          });
      });
    }
  }
};
