import Api from "@/services/Api";
import moment from "moment-timezone";

export default {
  namespaced: true,
  state: {
    activeChatId: null,
    chats: [],
    users: []
  },
  getters: {
    getChats: (state: any) => {
      return state.chats.sort(function(a: any, b: any) {
        // @ts-ignore
        return new Date(b.last_message_date) - new Date(a.last_message_date);
      });
    },
    getChatUsers: (state: any) => state.users,
    getActiveChatId: (state: any) => state.activeChatId
  },
  mutations: {
    SET_CHATS: (state: any, data: any) => {
      state.chats = data;
    },
    APPEND_CHAT: (state: any, data: any) => {
      state.chats.push(data);
    },
    SET_CHAT_USERS: (state: any, data: any) => {
      state.users = data;
    },
    SET_ACTIVE_CHAT_ID: (state: any, id: any) => {
      state.activeChatId = id;
      const index = state.chats.findIndex((i: any) => i.id == id);
      if (index > -1) {
        state.chats[index].message_counts = 0;
      }
    },
    SET_MESSAGE_TO_CHAT: (state: any, data: any) => {
      //@ts-ignore
      const index = state.chats.findIndex(i => i.id == data.chat_id);
      if (index > -1) {
        state.chats[index].last_message = data.attachment_name
          ? data.attachment_name
          : data.message;
        state.chats[index].last_message_date = moment()
          .tz("UTC")
          .format("YYYY-MM-DD H:mm:ss")
          .toString();
      } else {
        state.chats.push({
          id: data.chat_id,
          name: data.user.name,
          email: data.user.email,
          user_id: data.user.id,
          socket_id: data.user.socket_id,
          profile_pic: data.user.profile_pic,
          profile_link: data.user.profile_link,
          room_id: data.room_id,
          last_message: data.attachment_name
            ? data.attachment_name
            : data.message
        });
      }
    },
    RESET_CHATS: (state: any) => {
      state.chats = [];
    }
  },
  actions: {
    GET_CHATS: ({ commit }: { commit: any }, data: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .get(`chat?page=${data.page}`)
          .then(({ data }) => {
            commit("SET_CHATS", data.data);
            resolve(data.data);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    GET_CHAT: (_: any, data: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .post(`chat/find`, data)
          .then(({ data }) => {
            resolve(data.data);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    GET_CHAT_USERS: ({ commit }: { commit: any }, data: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .get(`chat/user?q=${data.q}&page=${data.page}`)
          .then(({ data }) => {
            commit("SET_CHAT_USERS", data.data);
            resolve(true);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    CREATE_CHAT: ({ commit }: { commit: any }, data: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .post(`chat`, data)
          .then(({ data }) => {
            commit("APPEND_CHAT", data.data);
            resolve(data.data);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    RESET_CHATS: ({ commit }: { commit: any }) => {
      commit("RESET_CHATS");
    }
  }
};
