import Api from "@/services/Api";

export default {
  namespaced: true,
  state: {
    recommendation: {}
  },
  getters: {
    getRecommendation: (state: any) => state.recommendation
  },
  mutations: {
    SET_RECOMMENDATION: (state: any, data: any) => {
      state.recommendation = data;
    },
    RESET_RECOMMENDATION: (state: any) => {
      state.recommendation = {};
    }
  },
  actions: {
    GET_RECOMMENDATION: ({ commit }: { commit: any }, data: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .get(`recommended/${data.type}?page=${data.page}`)
          .then(({ data }) => {
            commit("SET_RECOMMENDATION", data.data);
            resolve(true);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    RESET_RECOMMENDATION: ({ commit }: { commit: any }) => {
      commit("RESET_RECOMMENDATION");
    }
  }
};
