import Api from "@/services/Api";

export default {
  namespaced: true,
  state: {
    application: {}
  },
  getters: {
    getApplication: (state: any) => state.application
  },
  mutations: {
    SET_APPLICATION: (state: any, data: any) => {
      state.application = data;
    }
  },
  actions: {
    POST_MENTOR_APPLICATION: (_: any, data: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .post("mentor_application", data)
          .then(({ data }) => {
            resolve(data.data);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    POST_INVITATION_CODE: ({ commit }: { commit: any }, data: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .post("mentor_application/invitation_code", data)
          .then(({ data }) => {
            commit("SET_APPLICATION", data.data);
            resolve(true);
          })
          .catch(e => {
            reject(e);
          });
      });
    }
  }
};
