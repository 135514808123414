import Api from "@/services/Api";

export default {
  namespaced: true,
  state: {
    course: {},
    courses: []
  },
  getters: {
    getCourses: (state: any) => state.courses,
    getCourse: (state: any) => state.course
  },
  mutations: {
    SET_COURSES: (state: any, data: any) => {
      state.courses = data;
    },
    SET_COURSE: (state: any, data: any) => {
      state.course = data;
    }
  },
  actions: {
    CREATE_COURSE: (_: any, data: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .post("course", data)
          .then(({ data }) => {
            resolve(data.data.uuid);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    UPDATE_COURSE: (_: any, data: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .put(`course/${data.id}`, data.formData)
          .then(({ data }) => {
            resolve(data.data.uuid);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    GET_COURSE: ({ commit }: { dispatch: any; commit: any }, id: string) => {
      return new Promise((resolve, reject) => {
        Api()
          .get(`course/${id}`)
          .then(({ data }) => {
            commit("SET_COURSE", data.data);
            resolve(data.data);
          })
          .catch(e => {
            reject(e);
          });
      });
    }
  }
};
