import store from "@/store";

export default (module: string, nextUrl = "profile") => (
  to: any,
  from: any,
  next: any
) => {
  store.dispatch("helper/GET_MODULE_SETTINGS").then(() => {
    const moduleSetting = store.getters["helper/getModuleSettings"];
    /*console.log('feature enable status', moduleSetting,   module,  moduleSetting[module], moduleSetting[module] == 1)*/
    if (moduleSetting[module] == 1) {
      next();
    } else {
      next(`/${nextUrl}`);
    }
  });
};
