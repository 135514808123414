import Api from "@/services/Api";

export default {
  namespaced: true,
  state: {
    filters: {}
  },
  getters: {
    getFilters: (state: any) => state.filters
  },
  mutations: {
    SET_FILTERS: (state: any, data: any) => {
      state.filters = data;
    }
  },
  actions: {
    GET_FILTERS: ({ commit }: { commit: any }, data: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .get(`${data.type}/filter?q=${data.search}`)
          .then(({ data }) => {
            commit("SET_FILTERS", data.data);
            resolve(true);
          })
          .catch(e => {
            reject(e);
          });
      });
    }
  }
};
