import Api from "@/services/Api";

export default {
  namespaced: true,
  state: {
    profile: {},
    community: {},
    ratings: {},
    mentored_by: [],
    recentActivity: []
  },
  getters: {
    getProfile: (state: any) => state.profile,
    getCommunity: (state: any) => state.community,
    getRatings: (state: any) => state.ratings,
    getMentoredBy: (state: any) => state.mentored_by,
    getRecentActivity: (state: any) => state.recentActivity
  },
  mutations: {
    SET_PROFILE: (state: any, data: any) => {
      state.profile = data;
    },
    SET_COMMUNITY: (state: any, data: any) => {
      state.community = data;
    },
    SET_RATINGS: (state: any, data: any) => {
      state.ratings = data;
    },
    SET_MENTORED_BY: (state: any, data: any) => {
      state.mentored_by = data;
    },
    INCREASE_NOTIFICATION_COUNT: (state: any) => {
      state.profile.notifications_count++;
    },
    DECREASE_NOTIFICATION_COUNT: (state: any) => {
      if (state.profile.notifications_count > 0) {
        state.profile.notifications_count--;
      }
    },
    SET_NOTIFICATION_COUNT: (state: any, count: any) => {
      state.profile.notifications_count = count;
    },
    SET_RECENT_ACTIVITY: (state: any, data: any) => {
      state.recentActivity = data;
    },
    INCREASE_MESSAGE_COUNT: (state: any, id: any) => {
      const index = state.profile.messages_count?.indexOf(id);
      if (index == -1) {
        state.profile.messages_count.push(id);
      }
    },
    DECREASE_MESSAGE_COUNT: (state: any, id: any) => {
      const index = state.profile.messages_count?.indexOf(id);
      if (index > -1) {
        state.profile.messages_count.splice(index, 1);
      }
    }
  },
  actions: {
    GET_PROFILE: ({ dispatch, commit }: { dispatch: any; commit: any }) => {
      return new Promise((resolve, reject) => {
        Api()
          .get("profile")
          .then(({ data }) => {
            dispatch("auth/SET_AUTHORIZE", null, { root: true });
            commit("auth/SET_GUEST", data.data.is_guest, { root: true });
            commit("SET_PROFILE", data.data);
            resolve(data.data);
          })
          .catch(e => {
            commit("SET_PROFILE", {});
            reject(e);
          });
      });
    },
    GET_COMMUNITY: ({ commit }: { dispatch: any; commit: any }, data: any) => {
      return new Promise((resolve, reject) => {
        let url = `profile/community?filter=${data.filter}`;
        if (data.id) {
          url += `&id=${data.id}`;
        }
        Api()
          .get(url)
          .then(({ data }) => {
            commit("SET_COMMUNITY", data.data);
            resolve(true);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    GET_MENTORED_BY: ({ commit }: { dispatch: any; commit: any }) => {
      return new Promise((resolve, reject) => {
        Api()
          .get("profile/mentoredBy")
          .then(({ data }) => {
            commit("SET_MENTORED_BY", data.data);
            resolve(true);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    GET_RATINGS: ({ commit }: { dispatch: any; commit: any }, data: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .get(`profile/rating?page=${data.page}&profile=true&tab=${data.tab}`)
          .then(({ data }) => {
            commit("SET_RATINGS", data.data);
            resolve(data.data);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    GET_RECENT_ACTIVITY: (
      { commit }: { dispatch: any; commit: any },
      data: any
    ) => {
      return new Promise((resolve, reject) => {
        Api()
          .get(`profile/recent_activity?page=${data?.page}&tab=${data.tab}`)
          .then(({ data }) => {
            commit("SET_RECENT_ACTIVITY", data.data);
            resolve(data.data);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    SHARE_MENTORCAST_IN_RECENT_ACTIVITY: (_: any, id: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .post(`profile/recent_activity/${id}/share`)
          .then(() => {
            resolve(true);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    UPDATE_PROFILE: (_: any, data: FormData) => {
      return new Promise((resolve, reject) => {
        Api()
          .put("/profile", data)
          .then(() => {
            resolve(true);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    UPDATE_PROFILE_PHOTO: (_: any, data: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .put("/profile/photo", data)
          .then(() => {
            resolve(true);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    UPDATE_INFORMATION: (_: any, data: object) => {
      return new Promise((resolve, reject) => {
        Api()
          .put("profile/information", data)
          .then(() => {
            resolve(true);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    UPDATE_TIMEZONE: (_: any, data: object) => {
      return new Promise((resolve, reject) => {
        Api()
          .put("profile/timezone", data)
          .then(() => {
            resolve(true);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    UPDATE_NOTIFICATION_SETTING: (_: any, data: object) => {
      return new Promise((resolve, reject) => {
        Api()
          .put("profile/notification", data)
          .then(() => {
            resolve(true);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    UPDATE_PRIVACY: (_: any, data: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .put("profile/privacy", data)
          .then(() => {
            resolve(true);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    UPDATE_BADGE_TYPE: (_: any, data: object) => {
      return new Promise((resolve, reject) => {
        Api()
          .put("profile/badge_type", data)
          .then(() => {
            resolve(true);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    MENTEE_BECOME_MENTOR: (_: any, data: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .post("profile/mentee_become_mentor", data)
          .then(({ data }) => {
            resolve(data.data);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    CHECK_ACCOUNT_DELETE: () => {
      return new Promise((resolve, reject) => {
        Api()
          .get("profile/account_delete")
          .then(({ data }) => {
            resolve(data.data);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    POST_ACCOUNT_DELETE: () => {
      return new Promise((resolve, reject) => {
        Api()
          .post("profile/account_delete")
          .then(({ data }) => {
            resolve(data.message);
          })
          .catch(e => {
            reject(e);
          });
      });
    }
  }
};
