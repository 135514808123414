import Api from "@/services/Api";

export default {
  namespaced: true,
  getters: {},
  mutations: {},
  actions: {
    FILTER_NEWS_FEED: (_: any, data: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .post("news_feed", data)
          .then(({ data }) => {
            resolve(data.data);
          })
          .catch(e => {
            reject(e);
          });
      });
    }
  }
};
