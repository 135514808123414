import Api from "@/services/Api";

export default {
  namespaced: true,
  state: {
    seat: null
  },
  getters: {
    getSelectedSeat: (state: any) => state.seat
  },
  mutations: {
    SET_SEAT: (state: any, seat: any) => {
      state.seat = seat;
    }
  },
  actions: {
    CREATE_BOOKING: (_: any, data: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .post("booking", data)
          .then(data => {
            resolve(data.data);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    LAUNCH: (_: any, data: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .post(`${data.type}/${data.id}/launch`, data.data)
          .then(() => {
            resolve(true);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    JOIN: (_: any, data: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .post(`${data.type}/${data.id}/join`)
          .then(() => {
            resolve(true);
          })
          .catch(e => {
            reject(e);
          });
      });
    }
  }
};
