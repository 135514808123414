import store from "@/store";

// @ts-ignore
export default async function canAccessProgram(to, from, next) {
  try {
    const profile = await store.dispatch("profile/GET_PROFILE");
    const programModule = await store.dispatch(
      "program/GET_PROGRAM",
      to.params.id
    );

    if (
      programModule.mentor_id !== profile.id &&
      programModule.mentee_id !== profile.id
    ) {
      next("/404");
    } else {
      next();
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    next("/500");
  }
}
