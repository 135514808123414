import Api from "@/services/Api";

export default {
  namespaced: true,
  state: {
    payment_methods: {}
  },
  getters: {
    getPaymentMethods: (state: any) => state.payment_methods
  },
  mutations: {
    SET_PAYMENT_METHOD: (state: any, data: any) => {
      state.payment_methods = data;
    }
  },
  actions: {
    GET_PAYMENT_METHODS: ({ commit }: { commit: any }) => {
      return new Promise((resolve, reject) => {
        Api()
          .get("payment_method")
          .then(({ data }) => {
            commit("SET_PAYMENT_METHOD", data.data);
            resolve(true);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    ADD_PAYMENT_METHOD: ({ dispatch }: { dispatch: any }, data: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .post("payment_method", data)
          .then(() => {
            dispatch("GET_PAYMENT_METHODS");
            resolve(true);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    UPDATE_PAYMENT_METHOD: (_: any, data: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .put(`payment_method/${data.id}`, data)
          .then(() => {
            resolve(true);
          })
          .catch(e => {
            reject(e);
          });
      });
    }
  }
};
