import store from "@/store";

export default (to: any, from: any, next: any) => {
  if (localStorage.getItem("logged") || localStorage.getItem("token")) {
    store
      .dispatch("auth/VERIFY_USER")
      .then(() => {
        next();
      })
      .catch(err => {
        if (err.response.status == 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("logged");
          localStorage.removeItem("userType");
          next("/");
        }
      });
  } else {
    next("/");
  }
};
