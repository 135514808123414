import Api from "@/services/Api";

export default {
  namespaced: true,
  state: {
    likeableData: {}
  },
  actions: {
    SET_LIKE: ({ commit }: { dispatch: any; commit: any }, data: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .post(`${data.likeType}/${data.id}/like`, data.data)
          .then(({ data }) => {
            commit("SET_LIKABLE", data.data);
            resolve(data.data);
          })
          .catch(e => {
            reject(e);
          });
      });
    }
  },
  mutations: {
    SET_LIKABLE: (state: any, data: any) => {
      state.likeableData = data;
    }
  }
};
