import Api from "@/services/Api";

export default {
  namespaced: true,
  state: {
    sessions: {},
    session: {},
    ratings: {},
    overview: {},
    certificate: {}
  },
  getters: {
    getSessions: (state: any) => state.sessions,
    getSession: (state: any) => state.session,
    getRatings: (state: any) => state.ratings,
    getOverview: (state: any) => state.overview,
    getCertificate: (state: any) => state.certificate
  },
  mutations: {
    SET_SESSIONS: (state: any, data: any) => {
      state.sessions = data;
    },
    SET_SESSION: (state: any, data: any) => {
      state.session = data;
    },
    SET_RATINGS: (state: any, data: any) => {
      state.ratings = data;
    },
    RESET_SESSIONS: (state: any) => {
      state.sessions = {};
    },
    RESET_SESSION: (state: any) => {
      state.session = {};
    },
    RESET_RATINGS: (state: any) => {
      state.ratings = {};
    },
    SET_SESSION_OVERVIEW: (state: any, data: any) => {
      state.overview = data;
    },
    SET_CERTIFICATE: (state: any, data: any) => {
      state.certificate = data;
    }
  },
  actions: {
    GET_ALL_SESSIONS: (
      { commit }: { dispatch: any; commit: any },
      data: any
    ) => {
      return new Promise((resolve, reject) => {
        Api()
          .post(
            `session/filter?page=${data?.page}&type=${data?.type}`,
            data.filter
          )
          .then(({ data }) => {
            commit("SET_SESSIONS", data.data);
            resolve(data.data);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    GET_SESSION: ({ commit }: { dispatch: any; commit: any }, id: string) => {
      return new Promise((resolve, reject) => {
        Api()
          .get(`session/${id}`)
          .then(({ data }) => {
            commit("SET_SESSION", data.data);
            resolve(data.data);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    GET_RATINGS: ({ commit }: { dispatch: any; commit: any }, data: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .get(`session/${data.id}/rating?type=${data.type}`)
          .then(({ data }) => {
            commit("SET_RATINGS", data.data);
            resolve(data.data);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    GIVE_RATING: (_: any, data: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .post(`session/${data.id}/rating`, data.data)
          .then(() => {
            resolve(true);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    RESET_SESSIONS: ({ commit }: { commit: any }) => {
      commit("RESET_SESSIONS");
    },
    RESET_SESSION: ({ commit }: { commit: any }) => {
      commit("RESET_SESSION");
    },
    RESET_RATINGS: ({ commit }: { commit: any }) => {
      commit("RESET_RATINGS");
    },
    GET_SESSION_OVERVIEW: (
      { commit }: { dispatch: any; commit: any },
      id: any
    ) => {
      return new Promise((resolve, reject) => {
        Api()
          .get(`session/${id}/overview`)
          .then(({ data }) => {
            commit("SET_SESSION_OVERVIEW", data.data);
            resolve(true);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    GENERATE_ICS: (_: any, id: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .post(`session/${id}/ics`)
          .then(({ data }) => {
            resolve(data.data);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    GET_CHAT: (_: any, id: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .get(`session/${id}/chat`)
          .then(({ data }) => {
            resolve(data.data);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    DOWNLOAD_CERTIFICATE: (_: any, id: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .post(`session/${id}/certificate`, null, { responseType: "blob" })
          .then(({ data }) => {
            resolve(data);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    GET_CERTIFICATE: ({ commit }: { commit: any }, data: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .get(`session/${data.id}/certificate`)
          .then(({ data }) => {
            commit("SET_CERTIFICATE", data.data);
            resolve(data.data);
          })
          .catch(e => {
            reject(e);
          });
      });
    }
  }
};
