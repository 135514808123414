import Api from "@/services/Api";

export default {
  namespaced: true,
  state: {
    libraryContents: [],
    libraryItemsCount: 0,
    filters: {
      search: null,
      type: null,
      status: null
    },
    next_page: null,
    loading: false
  },
  mutations: {
    SET_LIBRARY_CONTENT: (state: any, data: any) => {
      state.libraryContent = data;
    },
    SET_LIBRARY_CONTENTS: (state: any, data: any) => {
      state.libraryContents = data;
    },
    SET_NEXT_PAGE: (state: any, data: any) => {
      state.next_page = data;
    },
    SET_LOADING: (state: any, data: any) => {
      state.loading = data;
    },
    RESET_FILTERS: (state: any) => {
      state.filters = { search: null, type: null, status: null };
    },
    SET_LIBRARY_ITEMS_COUNT: (state: any, data: any) =>
      (state.libraryItemsCount = data)
  },
  actions: {
    GET_ALL_CONTENT: ({ commit, state }: { commit: any; state: any }) => {
      return new Promise((resolve, reject) => {
        commit("SET_LOADING", true);
        Api()
          .get(`library-content`, { params: state.filters })
          .then(
            ({
              data: {
                data: { content, count }
              }
            }) => {
              commit("SET_LIBRARY_CONTENTS", content.data);
              commit("SET_NEXT_PAGE", content.next_page_url);
              commit("SET_LIBRARY_ITEMS_COUNT", count);
              commit("SET_LOADING", false);
              resolve({ content, count });
            }
          )
          .catch(e => {
            commit("SET_LOADING", false);
            reject(e);
          });
      });
    },
    GET_ALL_CONTENT_BY_MENTOR: (
      { commit, state }: { commit: any; state: any },
      id: number
    ) => {
      return new Promise((resolve, reject) => {
        commit("SET_LOADING", true);
        Api()
          .get(`mentors/${id}/library`, { params: state.filters })
          .then(
            ({
              data: {
                data: { content, count, mentor }
              }
            }) => {
              commit("SET_LIBRARY_CONTENTS", content.data);
              commit("SET_NEXT_PAGE", content.next_page_url);
              commit("SET_LIBRARY_ITEMS_COUNT", count);
              commit("SET_LOADING", false);
              resolve({ content, count, mentor });
            }
          )
          .catch(e => {
            commit("SET_LOADING", false);
            reject(e);
          });
      });
    },
    GET_NEXT_PAGE_DATA: ({
      commit,
      state
    }: {
      dispatch: any;
      commit: any;
      state: any;
    }) => {
      return new Promise((resolve, reject) => {
        commit("SET_LOADING", true);
        Api()
          .get(state.next_page)
          .then(
            ({
              data: {
                data: { content, count }
              }
            }) => {
              commit("SET_LIBRARY_CONTENTS", [
                ...state.libraryContents,
                ...content.data
              ]);
              commit("SET_NEXT_PAGE", content.next_page_url);
              commit("SET_LIBRARY_ITEMS_COUNT", count);
              commit("SET_LOADING", false);
              resolve({ content, count });
            }
          )
          .catch(e => {
            commit("SET_LOADING", false);
            reject(e);
          });
      });
    },
    CREATE_LIBRARY_CONTENT: ({ dispatch }: { dispatch: any }, data: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .post("library-content", data)
          .then(({ data }) => {
            resolve(data.data.id);
            dispatch("GET_ALL_CONTENT");
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    UPDATE_LIBRARY_CONTENT: (
      { dispatch }: { dispatch: any },
      { id, data }: { id: number; data: any }
    ) => {
      return new Promise((resolve, reject) => {
        Api()
          .post(`library-content/${id}`, data)
          .then(({ data }) => {
            resolve(data.data.id);
            dispatch("GET_ALL_CONTENT");
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    UPDATE_CONTENT_STATUS: ({ dispatch }: { dispatch: any }, data: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .post(`library-content/${data.id}/status`, { status: data.status })
          .then(({ data }) => {
            dispatch("GET_ALL_CONTENT");
            resolve(data.data.id);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    GET_CONTENT_STATISTICS: (_: any, data: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .get(`library-content/${data.id}/statistics`, {
            params: {
              ...data
            }
          })
          .then(
            ({
              data: {
                data: { purchases, total, content }
              }
            }) => {
              resolve({ purchases, total, content });
            }
          )
          .catch(e => {
            reject(e);
          });
      });
    },
    GET_CONTENT: (_: any, id: number) => {
      return new Promise((resolve, reject) => {
        Api()
          .get(`library-content/${id}`)
          .then(
            ({
              data: {
                data: { content }
              }
            }) => {
              resolve({ content });
            }
          )
          .catch(e => {
            reject(e);
          });
      });
    }
  }
};
