import Vue from "vue";
import Vuex from "vuex";
import agenda from "@/store/agenda";
import auth from "@/store/auth";
import category from "@/store/category";
import comment from "@/store/comment";
import certificates from "@/store/certificates";
import follow from "@/store/follow";
import helper from "@/store/helper";
import likeable from "@/store/likeable";
import mentorcast from "@/store/mentorcast";
import payment from "@/store/payment";
import privacy from "@/store/privacy";
import paginate from "@/store/paginate";
import booking from "@/store/booking";
import search from "@/store/search";
import mentor from "@/store/mentor";
import mentee from "@/store/mentee";
import post from "@/store/post";
import wallet from "@/store/wallet";
import profile from "@/store/profile";
import rating from "@/store/rating";
import session from "@/store/session";
import notification from "@/store/notification";
import chat from "@/store/chat";
import message from "@/store/message";
import otpcode from "@/store/otpcode";
import mentor_application from "@/store/mentor_application";
import filter from "@/store/filter";
import recommended from "@/store/recommended";
import stripePayment from "@/store/stripePayment";
import paypalPayment from "@/store/paypalPayment";
import newsFeed from "@/store/newsFeed";
import discount from "@/store/discount";
import translation from "@/store/translation";
import program from "@/store/program";
import milestone from "@/store/milestone";
import library_content from "@/store/library_content";
import mentorcast_content from "@/store/mentorcast_content";
import session_content from "@/store/session_content";
import course from "@/store/course";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    agenda,
    auth,
    otpcode,
    category,
    comment,
    certificates,
    follow,
    likeable,
    notification,
    paginate,
    mentee,
    mentor,
    payment,
    post,
    profile,
    privacy,
    mentorcast,
    session,
    booking,
    search,
    rating,
    helper,
    wallet,
    chat,
    message,
    mentor_application,
    newsFeed,
    filter,
    recommended,
    stripePayment,
    paypalPayment,
    discount,
    translation,
    program,
    milestone,
    library_content,
    mentorcast_content,
    session_content,
    course
  }
});
