import Api from "@/services/Api";

export default {
  namespaced: true,
  state: {
    followings: {},
    followers: {}
  },
  getters: {
    getFollowings: (state: any) => state.followings,
    getFollowers: (state: any) => state.followers
  },
  mutations: {
    SET_FOLLOWINGS: (state: any, data: any) => {
      state.followings = data;
    },
    SET_FOLLOWERS: (state: any, data: any) => {
      state.followers = data;
    },
    FOLLOW: (state: any, data: any) => {
      if (data.type) {
        const type = data.type == "followings" ? "is_following" : "is_follower";

        if (data.currentUserType == "followers") {
          data.type = "followers";
        }

        const index = state[data.type].data.findIndex(
          (e: any) => e.id == data.follower_id
        );

        if (index > -1) {
          state[data.type].data[index][type] = true;
        }
      }
    },
    UNFOLLOW: (state: any, data: any) => {
      const type = data.type == "followings" ? "is_following" : "is_follower";

      if (data.currentUserType == "followers") {
        data.type = "followers";
      }

      const index = state[data.type]?.data?.findIndex(
        (e: any) => e.id == data.follower_id
      );

      if (index > -1) {
        state[data.type].data[index][type] = false;
      }
    }
  },
  actions: {
    GET_FOLLOWINGS: ({ commit }: { dispatch: any; commit: any }, data: any) => {
      let url = `following?page=${data.page}`;
      if (data.id) {
        url = `following?id=${data.id}&page=${data.page}`;
      }
      return new Promise((resolve, reject) => {
        Api()
          .post(url, data.data)
          .then(({ data }) => {
            commit("SET_FOLLOWINGS", data.data);
            resolve(data.data);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    GET_FOLLOWERS: ({ commit }: { dispatch: any; commit: any }, data: any) => {
      let url = `follower?page=${data.page}`;
      if (data.id) {
        url = `follower?id=${data.id}&page=${data.page}`;
      }
      return new Promise((resolve, reject) => {
        Api()
          .post(url, data.data)
          .then(({ data }) => {
            commit("SET_FOLLOWERS", data.data);
            resolve(data.data);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    FOLLOW: ({ commit }: { dispatch: any; commit: any }, data: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .post("follow", data)
          .then(() => {
            commit("FOLLOW", data);
            resolve(true);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    UNFOLLOW: ({ commit }: { dispatch: any; commit: any }, data: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .post("unfollow", data)
          .then(() => {
            commit("UNFOLLOW", data);
            resolve(true);
          })
          .catch(e => {
            reject(e);
          });
      });
    }
  }
};
