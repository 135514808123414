import Api from "@/services/Api";

export default {
  namespaced: true,
  state: {
    categories: {}
  },
  getters: {
    getCategories: (state: any) => state.categories
  },
  mutations: {
    SET_CATEGORIES: (state: any, data: any) => {
      state.categories = data;
    }
  },
  actions: {
    GET_CATEGORIES: ({ commit }: { commit: any }) => {
      return new Promise((resolve, reject) => {
        console.log("category all");
        Api()
          .get("category")
          .then(({ data }) => {
            commit("SET_CATEGORIES", data.data);
            resolve(true);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    ADD_PAYMENT_METHOD: ({ dispatch }: { dispatch: any }, data: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .post("payment_method", data)
          .then(() => {
            dispatch("GET_PAYMENT_METHODS");
            resolve(true);
          })
          .catch(e => {
            reject(e);
          });
      });
    }
  }
};
