import Api from "@/services/Api";

export default {
  namespaced: true,
  state: {
    comments: {}
  },
  getters: {
    getComments: (state: any) => state.comments
  },
  mutations: {
    SET_COMMENTS: (state: any, data: any) => {
      state.comments = data;
    },
    PUT_COMMENT: (state: any, data: any) => {
      const index = state.comments.data.findIndex((e: any) => e.id == data.id);
      console.log(index);
      if (index > -1) {
        console.log(state.comments.data[index]);
        state.comments.data[index] = data.comment;
      }
    }
  },
  actions: {
    GET_COMMENTS: ({ commit }: { commit: any }, data: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .get(
            `comment/${data.type}/${data.id}?tab=${data?.tab}&page=${data.page}`
          )
          .then(({ data }) => {
            commit("SET_COMMENTS", data.data);
            resolve(data.data);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    CREATE_COMMENT: (_: any, data: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .post(`comment/${data.type}`, data.data)
          .then(({ data }) => {
            resolve(data.data);
          })
          .catch(e => {
            reject(e);
          });
      });
    }
  }
};
