import Api from "@/services/Api";

export default {
  namespaced: true,
  state: {
    mentee: {},
    statistics: {},
    mentoredBy: []
  },
  getters: {
    getMentee: (state: any) => state.mentee,
    getMentoredBy: (state: any) => state.mentoredBy,
    getStatistics: (state: any) => state.statistics
  },
  mutations: {
    SET_MENTEE: (state: any, data: any) => {
      state.mentee = data;
    },
    SET_MENTORED_BY: (state: any, data: any) => {
      state.mentoredBy = data;
    },
    SET_STATISTICS: (state: any, data: any) => {
      state.statistics = data;
    },
    RESET_PAGE: (state: any) => {
      state.mentee = {};
      state.statistics = {};
      state.mentoredBy = [];
    }
  },
  actions: {
    GET_MENTEE: ({ commit }: { dispatch: any; commit: any }, id_page: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .get(`mentorees/${id_page.id}?page=${id_page.page}`)
          .then(({ data }) => {
            console.log(data.data);

            commit("SET_MENTEE", data.data);
            resolve(data.data);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    GET_ACTIVITY: (_: any, data: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .get(
            `mentorees/${data.id}/activity?page=${data.page}&tab=${data.tab}`
          )
          .then(({ data }) => {
            resolve(data.data);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    GET_MENTORED_BY: ({ commit }: { dispatch: any; commit: any }, id: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .get(`mentorees/${id}/mentoredBy`)
          .then(({ data }) => {
            commit("SET_MENTORED_BY", data.data);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    GET_STATISTICS: ({ commit }: { dispatch: any; commit: any }, id: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .get(`mentorees/${id}/statistic`)
          .then(({ data }) => {
            commit("SET_STATISTICS", data.data);
            resolve(data.data);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    UPDATE_PROFILE: (_: any, data: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .put("/mentorees/profile", data)
          .then(() => {
            resolve(true);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    POST_REPORT_USER: (_: any, data: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .post(`/mentorees/${data.id}/report`, data)
          .then(({ data }) => {
            resolve(data.message);
          })
          .catch(e => {
            reject(e);
          });
      });
    }
  }
};
