import Api from "@/services/Api";

export default {
  namespaced: true,
  state: {
    sessionContents: [],
    next_page: null,
    loading: false
  },
  getters: {
    getSessionContent: (state: any) => state.sessionContents
  },
  mutations: {
    SET_SESSION_CONTENT: (state: any, data: any) => {
      state.sessionContent = data;
    },
    SET_SESSION_CONTENTS: (state: any, data: any) => {
      state.sessionContents = data;
    },
    SET_NEXT_PAGE: (state: any, data: any) => {
      state.next_page = data;
    },
    SET_LOADING: (state: any, data: any) => {
      state.loading = data;
    }
  },
  actions: {
    GET_ALL_CONTENT: (
      { commit }: { commit: any; state: any },
      { id, data }: { id: number; data: any }
    ) => {
      return new Promise((resolve, reject) => {
        commit("SET_LOADING", true);
        Api()
          .get(`session-content/${id}/content?page=${data.page}`)
          .then(data => {
            commit("SET_SESSION_CONTENTS", data.data);
            commit("SET_NEXT_PAGE", data.data.next_page_url);
            commit("SET_LOADING", false);
            resolve(data.data);
          })
          .catch(e => {
            commit("SET_LOADING", false);
            reject(e);
          });
      });
    },
    GET_NEXT_PAGE_DATA: ({
      commit,
      state
    }: {
      dispatch: any;
      commit: any;
      state: any;
    }) => {
      return new Promise((resolve, reject) => {
        commit("SET_LOADING", true);
        Api()
          .get(state.next_page)
          .then(
            ({
              data: {
                data: { content }
              }
            }) => {
              commit("SET_SESSION_CONTENTS", [...content.data]);
              commit("SET_NEXT_PAGE", content.next_page_url);
              commit("SET_LOADING", false);
              resolve({ content });
            }
          )
          .catch(e => {
            commit("SET_LOADING", false);
            reject(e);
          });
      });
    },
    CREATE_SESSION_CONTENT: (
      _: any,
      { id, data }: { id: number; data: any }
    ) => {
      return new Promise((resolve, reject) => {
        Api()
          .post(`session-content/${id}`, data)
          .then(({ data }) => {
            resolve(data.data.id);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    UPDATE_SESSION_CONTENT: (
      _: any,
      { id, data }: { id: number; data: any }
    ) => {
      return new Promise((resolve, reject) => {
        Api()
          .post(`session-content/${id}`, data)
          .then(({ data }) => {
            resolve(data.data.id);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    REMOVE_CONTENT: (_: any, id: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .delete(`session-content/${id}`)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    UPDATE_DOWNLOAD_COUNT: (_: any, id: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .put(`session-content/${id}/update-count`)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(e => {
            reject(e);
          });
      });
    }
  }
};
