import axios from "axios";
import router from "../router";

const API_URL = process.env.VUE_APP_API_URL;

export default () => {
  const token = localStorage.getItem("token");
  const api = axios.create({
    baseURL: API_URL,
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  });
  api.interceptors.response.use(
    response => response,
    error => {
      // whatever you want to do with the error
      if (error.message == "Network Error" || error.response?.status == 500) {
        // router.push("/500");
      } else if (error.response.status == 403) {
        router.push("/profile");
      } else if (error.response.status == 404) {
        // router.push("/404");
      } else if (error.response.status == 401 && error.config.method != "get") {
        window.dispatchEvent(
          new CustomEvent("alertify", {
            detail: { message: "Please login to continue.", type: "error" }
          })
        );
      } else if (error.response.status == 408) {
        router.push("408");
      }
      return Promise.reject(error);
    }
  );
  return api;
};
