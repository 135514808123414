import Api from "@/services/Api";

export default {
  namespaced: true,
  state: {
    users: {},
    mentorcasts: {}
  },
  getters: {
    getUsers: (state: any) => state.users,
    getMentorcasts: (state: any) => state.mentorcasts
  },
  mutations: {
    SET_USERS: (state: any, data: any) => {
      state.users = data;
    },
    SET_MENTORCASTS: (state: any, data: any) => {
      state.users = data;
    }
  },
  actions: {
    SEARCH_USERS: (_: any, filter: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .get(`search/user?page=${filter.page}&q=${filter?.q}`)
          .then(({ data }) => {
            resolve(data.data);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    SEARCH_MENTORS: ({ commit }: { commit: any }, data: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .post(`search/mentor?page=${data.page}`, data.filter)
          .then(({ data }) => {
            commit("SET_USERS", data.data);
            resolve(data.data);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    SEARCH_PEOPLES: ({ commit }: { commit: any }, data: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .post(`search/people?page=${data.page}`, data.filter)
          .then(({ data }) => {
            commit("SET_USERS", data.data);
            resolve(data.data);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    SEARCH_MENTORCASTS: ({ commit }: { commit: any }, data: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .get(
            `search/mentorcast?page=${data.page}&q=${data.q}&type=${data.type}&discover=true`
          )
          .then(({ data }) => {
            commit("SET_MENTORCASTS", data.data);
            resolve(data.data);
          })
          .catch(e => {
            reject(e);
          });
      });
    }
  }
};
