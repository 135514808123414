import Api from "@/services/Api";

export default {
  namespaced: true,
  state: {
    discount: {
      value: null
    }
  },
  getters: {
    getDiscount: (state: any) => state.discount
  },
  mutations: {
    SET_DISCOUNT: (state: any, data: any) => {
      if (data?.value) {
        state.discount = data;
      } else {
        state.discount = {};
      }
    }
  },
  actions: {
    GET_DISCOUNT: ({ commit }: { dispatch: any; commit: any }, data: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .get(`mentorcast/${data.id}/discount`)
          .then(({ data }) => {
            commit("SET_DISCOUNT", data.data);
            resolve(data.data);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    CREATE_DISCOUNT: (_: any, data: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .post(`mentorcast/${data.id}/discount/create`, data.data)
          .then(({ data }) => {
            resolve(data.message);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    CHECK_DISCOUNT: (_: any, data: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .post(`mentorcast/${data.id}/discount/check`, data.data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(e => {
            reject(e);
          });
      });
    }
  }
};
