import Api from "@/services/Api";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    UPDATE_OR_CREATE_MILESTONE: (_: any, payload: any) => {
      return new Promise((resolve, reject) => {
        let API;
        if (payload.milestoneId) {
          API = Api().post(
            `program/milestone/${payload.programId}/${payload.milestoneId}`,
            payload.data
          );
        } else {
          API = Api().post(
            `program/milestone/${payload.programId}`,
            payload.data
          );
        }

        API.then(({ data }) => {
          resolve(data.data);
        }).catch(e => {
          reject(e);
        });
      });
    },
    UPDATE_MILESTONE_STATUS: (_: any, payload: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .post(`program/milestone/${payload.milestoneId}/status`, payload.data)
          .then(({ data }) => {
            resolve(data.data);
          })
          .catch(e => {
            reject(e);
          });
      });
    }
  }
};
