import store from "@/store";

export default (type: string, nextUrl = "profile") => (
  to: any,
  from: any,
  next: any
) => {
  store.dispatch("profile/GET_PROFILE").then(() => {
    if (type === store.getters["profile/getProfile"].type) {
      next();
    } else {
      next(`/${nextUrl}`);
    }
  });
};
