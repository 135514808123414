import Api from "@/services/Api";

export default {
  namespaced: true,
  state: {
    notifications: {}
  },
  getters: {
    getNotifications: (state: any) => state.notifications
  },
  mutations: {
    SET_NOTIFICATIONS: (state: any, data: any) => {
      state.notifications = data;
    }
  },
  actions: {
    GET_NOTIFICATIONS: ({ commit }: { commit: any }, data = { page: 1 }) => {
      return new Promise((resolve, reject) => {
        Api()
          .get(`notification?page=${data.page}`)
          .then(({ data }) => {
            commit("SET_NOTIFICATIONS", data.data);
            resolve(data.data);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    MARK_AS_READ: () => {
      return new Promise((resolve, reject) => {
        Api()
          .post(`notification/read_all`)
          .then(() => {
            resolve(true);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    MARK_ONE_AS_READ: (_: any, id: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .post(`notification/read_one`, { id })
          .then(() => {
            resolve(true);
          })
          .catch(e => {
            reject(e);
          });
      });
    }
  }
};
