/* eslint-disable no-shadow */
import Api from "@/services/Api";

const state = {
  currentLanguage: undefined,
  languages: [],
  translation: null,
  translationKey: null,
  loading: false,
  error: null,
  // translatedPages: null,
  translatedLanguages: null,
  updateError: null,
  language: null,
  translationPending: true,
  platformLanguage: null
};

const getters = {
  getTranslation: (state: any) => state.translation,
  loaded: (state: any) => !state.loading && state.translation != null,
  // translatedPages: (state) => state.translatedPages,
  translatedLanguages: (state: any) =>
    state.translatedLanguages != null ? state.translatedLanguages : [],
  getLanguages: (state: any) => state.languages,
  getPlatformLanguage: (state: any) => state.platformLanguage,
  getCurrentLanguage: (state: any) => state.currentLanguage
};

const mutations = {
  clearErrors(state: any) {
    state.error = null;
    state.updateError = null;
  },
  getTranslation(state: any, data: any) {
    state.translatedLanguages = data.iso;
    state.translation = data.translations;
  },
  translateLanguage(state: any, data: any) {
    state.language = data;
    state.translationPending = false;
  },
  beforeTranslation(state: any) {
    state.translationPending = true;
  },
  SET_PLATFORM_LANGUAGE: (state: any, data: any) => {
    state.platformLanguage = data;
    state.language = data;
  },
  SET_CURRENT_LANGUAGE: (state: any, data: any) => {
    state.currentLanguage = data;
  },
  SET_LANGUAGES: (state: any, data: any) => {
    state.languages = data;
  }
};

const actions = {
  GET_TRANSLATION: (
    { state, commit }: { state: any; dispatch: any; commit: any },
    payload: any
  ) => {
    return new Promise((resolve, reject) => {
      commit("beforeTranslation");

      Api()
        .post(`translation`, {
          language: state.platformLanguage ?? payload.language
        })
        .then(({ data }) => {
          commit(
            "SET_CURRENT_LANGUAGE",
            state.platformLanguage ?? payload.language
          );
          commit("translateLanguage", data);
          resolve(data);
        })
        .catch(e => {
          reject(e);
        });
    });
  },
  async getTranslate({ commit }: any, payload: any) {
    commit("beforeTranslation");
    const data = await Api().post(`translation`, {
      language: payload.language
    });
    return data;
  },
  GET_PLATFORM_LANGUAGE: ({ commit }: any) => {
    return new Promise((resolve, reject) => {
      Api()
        .get(`default_language`)
        .then(({ data }) => {
          commit("SET_PLATFORM_LANGUAGE", data.data.key);
          resolve(data.data["639_1"]);
        })
        .catch(e => {
          reject(e);
        });
    });
  },
  GET_LANGUAGES: ({ commit }: any) => {
    return new Promise((resolve, reject) => {
      Api()
        .get(`language?only_available=true`)
        .then(({ data }) => {
          commit("SET_LANGUAGES", data.data);
          resolve(data.data);
        })
        .catch(e => {
          reject(e);
        });
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
