<script>
const REACT_URL = process.env.VUE_APP_REACT_URL;

export default {
  name: "HeightMonitor",
  data() {
    return {
      elementHeight: 0,
      windowHeight: 0,
      resizeObserver: null
    };
  },
  mounted() {
    // Initialize window height
    this.windowHeight = window.innerHeight;

    // Set up resize observer for the app container
    this.resizeObserver = new ResizeObserver(entries => {
      for (const entry of entries) {
        this.elementHeight = entry.contentRect.height;
        console.log("App container height:", this.elementHeight);

        this.listenHeightChanges({
          elementHeight: this.elementHeight,
          windowHeight: this.windowHeight,
          difference: this.windowHeight - this.elementHeight
        });
      }
    });

    // Start observing the app container
    const appElement = document.getElementById("app");
    if (appElement) {
      this.resizeObserver.observe(appElement);
    }

    // Listen for window resizes
    window.addEventListener("resize", this.handleWindowResize);
  },
  beforeDestroy() {
    // Clean up
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
    window.removeEventListener("resize", this.handleWindowResize);
  },
  methods: {
    listenHeightChanges(changes) {
      console.log("Height changes:", changes);
      window.parent.postMessage(
        { type: "HEIGHT_CHANGE", height: changes.elementHeight },
        REACT_URL
      );
    },
    handleWindowResize() {
      this.windowHeight = window.innerHeight;
    }
  }
};
</script>
