import { updateThemeColors } from "@/helpers/colorTheme";
import Api from "@/services/Api";

export default {
  namespaced: true,
  state: {
    version: "",
    colorSettingsLoaded: false,
    colorSettings: {},
    moduleSettings: {},
    mentorRegistration: "false",
    defaultConferencingPlatform: null,
    defaultPlatformCurrency: null,
    searchLanguage: null,
    badges: {},
    allBadges: {},
    rating_types: {},
    languages: [],
    locations: [],
    currencies: [],
    timezones: [],
    genders: [],
    platforms: [],
    audiences: [],
    footer: {},
    setting: {},
    landing_page: {},
    dynamicPage: {},
    mentorcastSettings: {}
  },
  getters: {
    getVersion: (state: any) => state.version,
    getColorSettings: (state: any) => state.colorSettings,
    getColorSettingsLoaded: (state: any) => state.colorSettingsLoaded,
    getModuleSettings: (state: any) => state.moduleSettings,
    getMentorRegistration: (state: any) => state.mentorRegistration,
    getDefaultConferencingPlatform: (state: any) =>
      state.defaultConferencingPlatform,
    getDefaultPlatformCurrency: (state: any) => state.defaultPlatformCurrency,
    getSearchLanguage: (state: any) => state.searchLanguage,
    getRatingTypes: (state: any) => state.rating_types,
    getBadges: (state: any) => state.badges,
    getAllBadges: (state: any) => state.allBadges,
    getLanguages: (state: any) => state.languages,
    getLocations: (state: any) => state.locations,
    getTimezones: (state: any) => state.timezones,
    getGenders: (state: any) => state.genders,
    getMentoringPlatforms: (state: any) => state.platforms,
    getMentorcastAudiences: (state: any) => state.audiences,
    getFooter: (state: any) => state.footer,
    getSetting: (state: any) => state.setting,
    getLandingPage: (state: any) => state.landing_page,
    getPaymentTermsLink: (state: any) => state.footer.payment_terms,
    getTermsAndConditionLink: (state: any) => state.footer.terms_and_condition,
    getDynamicPage: (state: any) => state.dynamicPage,
    getMentorcastSettings: (state: any) => state.mentorcastSettings,
    getHomePageTextPrimaryStyle: (state: any) => {
      if (state.setting?.homepage_color) {
        return `color: ${state.setting.homepage_color} !important`;
      }
    },
    getHomePageBgPrimaryStyle: (state: any) => {
      if (state.setting?.homepage_color) {
        return `background-color: ${state.setting.homepage_color}`;
      }
    }
  },
  mutations: {
    SET_VERSION: (state: any, data: any) => {
      state.version = data;
    },
    SET_MODULE_SETTINGS: (state: any, data: any) => {
      state.moduleSettings = data;
    },
    SET_COLOR_SETTINGS: (state: any, data: any) => {
      state.colorSettings = data;
      state.colorSettingsLoaded = true;
    },
    SET_MENTOR_REGISTRATION: (state: any, data: any) => {
      state.mentorRegistration = data;
    },
    SET_DEFAULT_CONFERENCING_PLATFORM: (state: any, data: any) => {
      state.defaultConferencingPlatform = data;
    },
    SET_DEFAULT_PLATFORM_CURRENCY: (state: any, data: any) => {
      state.defaultPlatformCurrency = data;
    },
    SET_SEARCH_LANGUAGE: (state: any, data: any) => {
      state.searchLanguage = data;
    },
    SET_BADGES: (state: any, data: any) => {
      state.badges = data;
    },
    SET_ALL_BADGES: (state: any, data: any) => {
      state.allBadges = data;
    },
    SET_RATING_TYPES: (state: any, data: any) => {
      state.rating_types = data;
    },
    SET_LANGUAGES: (state: any, data: any) => {
      state.languages = data;
    },
    SET_LOCATIONS: (state: any, data: any) => {
      state.locations = data;
    },
    SET_CURRENCIES: (state: any, data: any) => {
      state.currencies = data;
    },
    SET_TIMEZONES: (state: any, data: any) => {
      state.timezones = data;
    },
    SET_GENDERS: (state: any, data: any) => {
      state.genders = data;
    },
    SET_PLATFORMS: (state: any, data: any) => {
      state.platforms = data;
    },
    SET_AUDIENCES: (state: any, data: any) => {
      state.audiences = data;
    },
    SET_FOOTER: (state: any, data: any) => {
      state.footer = data;
    },
    SET_LANDING_PAGE: (state: any, data: any) => {
      state.landing_page = data;
    },
    SET_SETTING: (state: any, data: any) => {
      state.setting = data;
    },
    SET_DYNAMIC_PAGE: (state: any, data: any) => {
      state.dynamicPage = data;
    },
    SET_MENTORCAST_SETTINGS: (state: any, data: any) => {
      state.mentorcastSettings = data;
    }
  },
  actions: {
    GET_VERSION: ({ commit }: { commit: any }) => {
      return new Promise((resolve, reject) => {
        Api()
          .get("version")
          .then(({ data }) => {
            commit("SET_VERSION", data.data);
            resolve(true);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    GET_COLOR_SETTINGS: ({ commit }: { commit: any }) => {
      return new Promise((resolve, reject) => {
        const params = new URLSearchParams(window.location.search);

        if (params.get("colors")) {
          try {
            const colors = JSON.parse(atob(params.get("colors") || ""));
            console.log("params", colors);
            commit("SET_COLOR_SETTINGS", colors);
            updateThemeColors({
              enable_color_customization: "1",
              ...colors
            });
            resolve(colors);
          } catch (e) {
            reject(e);
          }
        } else {
          Api()
            .get("colors-setting")
            .then(({ data }) => {
              commit("SET_COLOR_SETTINGS", data.data);
              updateThemeColors(data.data);
              resolve(data.data);
            })
            .catch(e => {
              reject(e);
            });
        }
      });
    },
    GET_MODULE_SETTINGS: ({ commit }: { commit: any }) => {
      return new Promise((resolve, reject) => {
        Api()
          .get("modules-settings")
          .then(({ data }) => {
            commit("SET_MODULE_SETTINGS", data.data);
            resolve(data.data);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    GET_MENTOR_REGISTRATION: ({ commit }: { commit: any }) => {
      return new Promise((resolve, reject) => {
        Api()
          .get("mentor_registration_setting")
          .then(({ data }) => {
            commit("SET_MENTOR_REGISTRATION", data.data);
            resolve(true);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    GET_DEFAULT_CONFERENCING_PLATFORM: ({ commit }: { commit: any }) => {
      return new Promise((resolve, reject) => {
        Api()
          .get("default_conferencing_platform")
          .then(({ data }) => {
            commit("SET_DEFAULT_CONFERENCING_PLATFORM", data.data);
            resolve(true);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    GET_DEFAULT_PLATFORM_CURRENCY: ({ commit }: { commit: any }) => {
      return new Promise((resolve, reject) => {
        Api()
          .get("default_currency")
          .then(({ data }) => {
            commit("SET_DEFAULT_PLATFORM_CURRENCY", data.data.name);
            resolve(true);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    GET_SEARCH_LANGUAGE: ({ commit }: { commit: any }) => {
      return new Promise((resolve, reject) => {
        Api()
          .get("search_language")
          .then(({ data }) => {
            commit("SET_SEARCH_LANGUAGE", data.data);
            resolve(data.data);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    GET_BADGES: ({ commit }: { commit: any }, data: any) => {
      return new Promise((resolve, reject) => {
        const filter =
          data?.type && data?.badge_type
            ? `?type=${data?.type}&badge_type=${data?.badge_type}`
            : "";

        Api()
          .get(`badge${filter}`)
          .then(({ data }) => {
            commit("SET_BADGES", data.data);
            // resolve()
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    GET_RATING_TYPES: ({ commit }: { commit: any }, data: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .get(`rating/type?type=${data.type}`)
          .then(({ data }) => {
            commit("SET_RATING_TYPES", data.data);
            resolve(true);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    GET_LANGUAGES: ({ commit }: { commit: any }) => {
      return new Promise((resolve, reject) => {
        Api()
          .get("language")
          .then(({ data }) => {
            commit("SET_LANGUAGES", data.data);
            resolve(true);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    GET_CURRENCIES: ({ commit }: { commit: any }) => {
      return new Promise((resolve, reject) => {
        Api()
          .get("currency")
          .then(({ data }) => {
            commit("SET_CURRENCIES", data.data);
            resolve(true);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    GET_TIMEZONES: ({ commit }: { commit: any }) => {
      return new Promise((resolve, reject) => {
        Api()
          .get("timezone")
          .then(({ data }) => {
            commit("SET_TIMEZONES", data.data);
            resolve(true);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    GET_LOCATIONS: ({ commit }: { commit: any }, search: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .get(`location?q=${search}`)
          .then(({ data }) => {
            commit("SET_LOCATIONS", data.data);
            resolve(true);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    GET_GENDERS: ({ commit }: { commit: any }) => {
      return new Promise((resolve, reject) => {
        Api()
          .get("gender")
          .then(({ data }) => {
            commit("SET_GENDERS", data.data);
            resolve(true);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    STORE_TEMP_IMAGE: (_: any, data: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .post("image", { image: data })
          .then(({ data }) => {
            resolve(data.data);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    STORE_CHAT_ATTACHMENT: (_: any, data: any) => {
      const formData = new FormData();
      formData.append("file", data);
      return new Promise((resolve, reject) => {
        Api()
          .post("chat-attachment", formData)
          .then(({ data }) => {
            resolve(data.data);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    GET_ALL_BADGES: ({ commit }: { commit: any }) => {
      return new Promise((resolve, reject) => {
        Api()
          .get("badge/all")
          .then(({ data }) => {
            commit("SET_ALL_BADGES", data.data);
            // resolve()
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    GET_MENTORING_PLATFORMS: ({ commit }: { commit: any }) => {
      return new Promise((resolve, reject) => {
        Api()
          .get("mentoring_platform")
          .then(({ data }) => {
            commit("SET_PLATFORMS", data.data);
            resolve(true);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    GET_MENTORCAST_AUDIENCES: ({ commit }: { commit: any }) => {
      return new Promise((resolve, reject) => {
        Api()
          .get("mentorcast_audience")
          .then(({ data }) => {
            commit("SET_AUDIENCES", data.data);
            resolve(true);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    GET_MENTORCAST_SETTINGS: ({ commit }: { commit: any }) => {
      return new Promise((resolve, reject) => {
        Api()
          .post("mentorcast/settings")
          .then(({ data }) => {
            commit("SET_MENTORCAST_SETTINGS", data.data);
            resolve(data.data);
          })
          .catch(e => {
            reject(e);
          });
      });
    },

    GET_FOOTER: ({ commit }: { commit: any }) => {
      return new Promise((resolve, reject) => {
        Api()
          .get("footer")
          .then(({ data }) => {
            commit("SET_FOOTER", data.data);
            resolve(true);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    GET_SETTING: ({ commit }: { commit: any }) => {
      return new Promise((resolve, reject) => {
        Api()
          .get("setting")
          .then(({ data }) => {
            commit("SET_SETTING", data.data);
            resolve(true);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    GET_LANDING_PAGE: ({ commit }: { commit: any }) => {
      return new Promise((resolve, reject) => {
        Api()
          .get("landing_page")
          .then(({ data }) => {
            commit("SET_LANDING_PAGE", data.data);
            resolve(true);
          })
          .catch(e => {
            reject(e);
          });
      });
    },

    GET_DYNAMIC_PAGE: ({ commit }: { commit: any }, slug: string) => {
      return new Promise((resolve, reject) => {
        Api()
          .get(`page/${slug}`)
          .then(({ data }) => {
            commit("SET_DYNAMIC_PAGE", data.data);
            resolve(true);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    POST_CONTACT_FORM: (_: any, data: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .post(`contact_us`, data)
          .then(({ data }) => {
            resolve(data.message);
          })
          .catch(e => {
            reject(e);
          });
      });
    }
  }
};
