import Api from "@/services/Api";

export default {
  namespaced: true,
  state: {
    skipMentorcastLicensePopup: null,
    mentorcast: {},
    mentorcasts: [],
    seats: {},
    overview: {},
    ratings: {},
    certificate: {},
    mentorcastParticipants: {}
  },
  getters: {
    getSkipMentorcastLicensePopupValue: (state: any) =>
      state.skipMentorcastLicensePopup,
    getMentorcasts: (state: any) => state.mentorcasts,
    getMentorcastSeats: (state: any) => state.seats,
    getMentorcastOverview: (state: any) => state.overview,
    getMentorcastRatings: (state: any) => state.ratings,
    getMentorcast: (state: any) => state.mentorcast,
    getCertificate: (state: any) => state.certificate,
    getMentorcastParticipants: (state: any) => state.mentorcastParticipants
  },
  mutations: {
    SET_SKIP_MENTORCAST_LICENSE_POPUP: (state: any, data: any) => {
      state.skipMentorcastLicensePopup = data;
    },
    SET_MENTORCASTS: (state: any, data: any) => {
      state.mentorcasts = data;
    },
    SET_MENTORCAST_SEATS: (state: any, data: any) => {
      state.seats = data;
    },
    SET_MENTORCAST_OVERVIEW: (state: any, data: any) => {
      state.overview = data;
    },
    SET_MENTORCAST: (state: any, data: any) => {
      state.mentorcast = data;
    },
    SET_MENTORCAST_RATINGS: (state: any, data: any) => {
      state.ratings = data;
    },
    SET_MENTORCAST_CERTIFICATE: (state: any, data: any) => {
      state.certificate = data;
    },
    SET_MENTORCAST_PARTICIPANTS: (state: any, data: any) => {
      state.mentorcastParticipants = data;
    },
    UPDATE_MENTORCAST_COUNT: (state: any, count = 0) => {
      state.mentorcast.comments_count = count;
    }
  },
  actions: {
    GET_ALL_MENTORCAST: (
      { commit }: { dispatch: any; commit: any },
      data = { page: 1, data: {} }
    ) => {
      return new Promise((resolve, reject) => {
        Api()
          .post(`mentorcast/filter?page=${data.page}`, data.data)
          .then(({ data }) => {
            commit("SET_MENTORCASTS", data.data);
            resolve(data.data);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    CREATE_MENTORCAST: (_: any, data: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .post("mentorcast", data)
          .then(({ data }) => {
            resolve(data.data.uuid);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    UPDATE_MENTORCAST: (_: any, data: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .put(`mentorcast/${data.id}`, data.formData)
          .then(() => {
            resolve(data.id);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    GET_MENTORCAST: (
      { commit }: { dispatch: any; commit: any },
      id: string
    ) => {
      return new Promise((resolve, reject) => {
        Api()
          .get(`mentorcast/${id}`)
          .then(({ data }) => {
            commit("SET_MENTORCAST", data.data);
            resolve(data.data);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    GET_SKIP_MENTORCAST_LICENSE_POPUP: ({ commit }: { commit: any }) => {
      return new Promise((resolve, reject) => {
        Api()
          .get(`skip_mentorcast_license_popup`)
          .then(({ data }) => {
            commit("SET_SKIP_MENTORCAST_LICENSE_POPUP", data.data);
            resolve(data.data);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    POST_INVITATION_PASSWORD: (_: any, data: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .post(`mentorcast/${data.id}/invitation_password`, data)
          .then(({ data }) => {
            resolve(data.data);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    GET_MENTORCAST_SEATS: (
      { commit }: { dispatch: any; commit: any },
      data: any
    ) => {
      return new Promise((resolve, reject) => {
        Api()
          .get(
            `mentorcast/${data.id}/seat?all_seats=${data.all_seats}&page=${data.page}`
          )
          .then(({ data }) => {
            commit("SET_MENTORCAST_SEATS", data);
            resolve(data);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    GET_MENTORCAST_OVERVIEW: (
      { commit }: { dispatch: any; commit: any },
      id: any
    ) => {
      return new Promise((resolve, reject) => {
        Api()
          .get(`mentorcast/${id}/overview`)
          .then(({ data }) => {
            commit("SET_MENTORCAST_OVERVIEW", data.data);
            resolve(true);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    GET_RATINGS: ({ commit }: { commit: any }, id: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .get(`mentorcast/${id}/rating`)
          .then(({ data }) => {
            commit("SET_MENTORCAST_RATINGS", data.data);
            resolve(true);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    GIVE_RATING: (_: any, data: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .post(`mentorcast/${data.id}/rating`, data.data)
          .then(() => {
            resolve(true);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    GENERATE_ICS: (_: any, id: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .post(`mentorcast/${id}/ics`)
          .then(({ data }) => {
            resolve(data.data);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    GET_CERTIFICATES: (_: any, data: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .get(`mentorcast/${data.id}/certificate?page=${data.page}`)
          .then(({ data }) => {
            resolve(data.data);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    GET_CERTIFICATE: ({ commit }: { commit: any }, data: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .get(`mentorcast/${data.id}/mentorcastSeat`)
          .then(({ data }) => {
            commit("SET_MENTORCAST_CERTIFICATE", data.data);
            resolve(data.data);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    DOWNLOAD_CERTIFICATE: (_: any, id: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .post(`mentorcast/${id}/certificate`, null, { responseType: "blob" })
          .then(({ data }) => {
            resolve(data);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    FINANCIAL_OVERVIEW: (_: any, data: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .post("mentorcast/financial_overview", data)
          .then(({ data }) => {
            resolve(data.data);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    ACCEPT_ZERO_MENTORCAST_LICENCE: (_: any, data: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .post("mentorcast/zero_licence", data)
          .then(() => {
            resolve(true);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    GET_MENTORCAST_PARTICIPANTS: (
      { commit }: { dispatch: any; commit: any },
      body: any
    ) => {
      return new Promise((resolve, reject) => {
        const qs = Object.keys(body.data)
          .map(
            key =>
              `${encodeURIComponent(key)}=${encodeURIComponent(body.data[key])}`
          )
          .join("&");
        console.log(qs);
        Api()
          .get(`mentorcast/${body.id}/participant?${qs}`)
          .then(({ data }) => {
            commit("SET_MENTORCAST_PARTICIPANTS", data.data);
            resolve(data.data);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    MUTE_MENTORCAST_PARTICIPANT: (_: any, data: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .post(`mentorcast/${data.id}/participant/${data.seat_id}/mute`, {
            is_muted: data.is_muted
          })
          .then(() => {
            resolve(true);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    GENERATE_MENTORCAST_PARTICIPANTS: (_: any, id: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .post(`mentorcast/${id}/participant/export`, null, {
            responseType: "blob"
          })
          .then(({ data }) => {
            resolve(data);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    UPGRADE_MENTORCAST_SEAT: (_: any, payload: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .post(`mentorcast/${payload.id}/seat/upgrade`, payload.data)
          .then(() => {
            resolve(true);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    GET_MENTORCAST_GALLERY: () => {
      return new Promise((resolve, reject) => {
        Api()
          .get(`mentorcast/gallery`)
          .then(({ data }) => {
            resolve(data.data);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    SET_MENTORCAST_COMMENTS_COUNT: (
      { commit }: { dispatch: any; commit: any },
      id: any
    ) => {
      return new Promise((resolve, reject) => {
        Api()
          .post(`mentorcast/${id}/comment/update`)
          .then(() => {
            commit("UPDATE_MENTORCAST_COUNT");
            resolve(true);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    GET_MENTORCAST_COMMENTS_COUNT: (
      { commit }: { dispatch: any; commit: any },
      id: any
    ) => {
      return new Promise((resolve, reject) => {
        Api()
          .get(`mentorcast/${id}/comment`)
          .then(({ data }) => {
            commit("UPDATE_MENTORCAST_COUNT", data.data);
            resolve(true);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    GET_CHAT: (_: any, id: string) => {
      return new Promise((resolve, reject) => {
        Api()
          .get(`mentorcast/${id}/chat`)
          .then(({ data }) => {
            resolve(data.data);
          })
          .catch(e => {
            reject(e);
          });
      });
    }
  }
};
