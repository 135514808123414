import Api from "@/services/Api";

export default {
  namespaced: true,
  state: {
    payment_methods: {}
  },
  getters: {
    getPaymentMethods: (state: any) => state.payment_methods
  },
  mutations: {
    SET_PAYMENT_METHOD: (state: any, data: any) => {
      state.payment_methods = data;
    },
    REMOVE_PAYMENT_METHOD: (state: any, id: string) => {
      const index = state.payment_methods.findIndex((i: any) => i.id == id);
      if (index > -1) {
        state.payment_methods.splice(index, 1);
      }
    }
  },
  actions: {
    GET_PAYMENT_METHODS: ({ commit }: { commit: any }) => {
      return new Promise((resolve, reject) => {
        Api()
          .get("stripe/payment_method")
          .then(({ data }) => {
            commit("SET_PAYMENT_METHOD", data.data);
            resolve(true);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    ADD_PAYMENT_METHOD: ({ dispatch }: { dispatch: any }, data: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .post("stripe/payment_method", data)
          .then(() => {
            dispatch("GET_PAYMENT_METHODS");
            resolve(true);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    DELETE_PAYMENT_METHOD: ({ commit }: { commit: any }, id: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .delete(`stripe/payment_method/${id}`)
          .then(() => {
            commit("REMOVE_PAYMENT_METHOD", id);
            resolve(true);
          })
          .catch(e => {
            reject(e);
          });
      });
    }
  }
};
