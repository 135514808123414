
import Vue from "vue";

export default Vue.extend({
  name: "LoadMore",
  props: {
    isShow: {
      type: Boolean,
      required: true
    },
    text: {
      type: String
      /*default: (this as any).$t('category.categoryNoRecord')*/
    },
    empty: {
      type: Number,
      required: true
    }
  },
  data() {
    return {};
  },
  methods: {}
});
