import Api from "@/services/Api";

export default {
  namespaced: true,
  state: {
    mentor: {},
    mentors: [],
    activity: {},
    mentorcasts: {},
    library_contents: [],
    statistics: {},
    ratings: {}
  },
  getters: {
    getMentor: (state: any) => state.mentor,
    getRatings: (state: any) => state.ratings,
    getActivity: (state: any) => state.activity,
    getMentorcasts: (state: any) => state.mentorcasts,
    getStatistics: (state: any) => state.statistics
  },
  mutations: {
    SET_MENTOR: (state: any, data: any) => {
      state.mentor = data;
    },
    SET_RATINGS: (state: any, data: any) => {
      state.ratings = data;
    },
    SET_ACTIVITY: (state: any, data: any) => {
      state.activity = data;
    },
    SET_MENTORCASTS: (state: any, data: any) => {
      state.mentorcasts = data;
    },
    SET_STATISTICS: (state: any, data: any) => {
      state.statistics = data;
    },
    RESET_PAGE: (state: any) => {
      state.mentor = {};
      state.mentors = [];
      state.activity = {};
      state.mentorcasts = {};
      state.statistics = {};
      state.ratings = {};
    }
  },
  actions: {
    GET_MENTOR: ({ commit }: { dispatch: any; commit: any }, data: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .get(`mentors/${data.id}?page=${data.page}`)
          .then(({ data }) => {
            commit("SET_MENTOR", data.data);
            resolve(data.data);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    GET_ACTIVITY: ({ commit }: { dispatch: any; commit: any }, data: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .get(`mentors/${data.id}/activity?tab=${data.tab}&page=${data.page}`)
          .then(({ data }) => {
            commit("SET_ACTIVITY", data.data);
            resolve(data.data);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    GET_MENTORCASTS: (
      { commit }: { dispatch: any; commit: any },
      data: any
    ) => {
      return new Promise((resolve, reject) => {
        Api()
          .get(
            `mentors/${data.id}/mentorcasts?page=${data?.page}&type=${data?.type}&available=true`
          )
          .then(({ data }) => {
            commit("SET_MENTORCASTS", data.data);
            resolve(data.data);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    GET_STATISTICS: ({ commit }: { dispatch: any; commit: any }, id: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .get(`mentors/${id}/statistic`)
          .then(({ data }) => {
            commit("SET_STATISTICS", data.data);
            resolve(data.data);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    GET_RATINGS: ({ commit }: { dispatch: any; commit: any }, data: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .get(
            `mentors/${data.id}/rating?page=${data.page}&profile=true&tab=${data.tab}`
          )
          .then(({ data }) => {
            commit("SET_RATINGS", data.data);
            resolve(data.data);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    UPDATE_PROFILE: (_: any, data: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .put("/mentors/profile", data)
          .then(() => {
            resolve(true);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    UPDATE_INFORMATION: (_: any, data: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .put("/mentors/profile/information", data)
          .then(() => {
            resolve(true);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    POST_REPORT_USER: (_: any, data: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .post(`/mentors/${data.id}/report`, data)
          .then(({ data }) => {
            resolve(data.message);
          })
          .catch(e => {
            reject(e);
          });
      });
    }
  }
};
