// @ts-ignore
import i18n from "../i18n";
import store from "@/store";

export default {
  async updateTranslations(language = "", force = false) {
    const translation = await store.getters["translation/getTranslation"];
    const platformLang = await store.getters["translation/getPlatformLanguage"];
    const platformLangFetch =
      platformLang ??
      (await store.dispatch("translation/GET_PLATFORM_LANGUAGE"));

    if (!force && platformLangFetch) {
      language = platformLangFetch;
    }

    let data =
      translation ??
      (await store.dispatch("translation/GET_TRANSLATION", {
        language
      }));

    i18n.locale = language;

    if (force) {
      data = await store.dispatch("translation/GET_TRANSLATION", {
        language
      });
    }

    store.commit("translation/getTranslation", { ...data.data });
    if (data.status === 200 || data.data.translations?.en) {
      Object.keys(data.data.translations).forEach(locale => {
        i18n.setLocaleMessage(locale, { ...data.data.translations[locale] });
      });
    } else {
      const locales = require.context(
        "../i18n/locales",
        true,
        /[A-Za-z0-9-_,\s]+\.json$/i
      );
      const messages = {} as any;
      locales.keys().forEach(key => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i);
        if (matched && matched.length > 1) {
          const locale = matched[1];
          messages[locale] = locales(key);
          i18n.setLocaleMessage(locale, messages[locale]);
        }
      });
    }
  }
};
