import Api from "@/services/Api";

export default {
  namespaced: true,
  state: {
    email: ""
  },
  getters: {
    getEmail: (state: any) => state.email
  },
  mutations: {
    SET_EMAIL: (state: any, email: any) => {
      state.email = email;
    }
  },
  actions: {
    GENERATE_OTP: ({ commit }: { commit: any }, data: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .post("otp_code", data)
          .then(() => {
            commit("SET_EMAIL", data.email);
            resolve(true);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    VERIFY_OTP: (_: any, data: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .post("otp_code/verify", data)
          .then(() => {
            resolve(true);
          })
          .catch(e => {
            reject(e);
          });
      });
    }
  }
};
