import Api from "@/services/Api";

export default {
  namespaced: true,
  state: {
    wallets: []
  },
  getters: {
    getWallets: (state: any) => state.wallets
  },
  mutations: {
    SET_WALLETS: (state: any, data: any) => {
      state.wallets = data;
    }
  },
  actions: {
    GET_ALL_WALLET: ({ commit }: { dispatch: any; commit: any }, data: any) => {
      return new Promise((resolve, reject) => {
        console.log(data);
        Api()
          .get(`wallet?page=${data.page}&date=${data.date}`)
          .then(({ data }) => {
            commit("SET_WALLETS", data.data);
            resolve(data.data);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    GENERATE_INVOICE: (_: any, id: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .post(`wallet/${id}/pdf`, null, { responseType: "blob" })
          .then(({ data }) => {
            resolve(data);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    WITHDRAW_WALLET: (_: any, data: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .post("wallet/withdraw", data)
          .then(({ data }) => {
            resolve(data.message);
          })
          .catch(e => {
            reject(e);
          });
      });
    }
  }
};
