import store from "@/store";

export default (route = "profile") => (to: any, from: any, next: any) => {
  store.dispatch("profile/GET_PROFILE").then(() => {
    if (to.params.id != store.getters["profile/getProfile"].uuid) {
      next();
    } else {
      next(`/${route}`);
    }
  });
};
