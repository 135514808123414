import Api from "@/services/Api";
import moment from "moment";

export default {
  namespaced: true,
  state: {
    messages: []
  },
  getters: {
    getMessages: (state: any) => state.messages
  },
  mutations: {
    SET_MESSAGES: (state: any, data: any) => {
      state.messages = data;
    },
    APPEND_MESSAGE: (state: any, data: any) => {
      state.messages.unshift(data);
    },
    MESSAGE_SEEN_DELIVERED: (state: any, data: any) => {
      console.log(data, "MESSAGE_SEEN_DELIVERED");

      const index = state.messages.findIndex((i: any) => i.id == data.id);
      if (index > -1) {
        state.messages[index].delivered_at = data.delivered_at;
        state.messages[index].seen_at = data.seen_at;
        state.messages[index].updated_at = new Date().toISOString();
      }
    },
    MESSAGE_SENT: (state: any, data: any) => {
      state.messages[0].id = data.id;
      state.messages[0].time = moment()
        .format("HH:mm A")
        .toString();
      state.messages[0].created_on = moment()
        .format("hh:mm A, DD/MM/YYYY")
        .toString();
      state.messages[0].updated_at = new Date().toISOString();
    },
    MESSAGE_DELIVERED: (state: any, data: any) => {
      console.log(data, "MESSAGE_DELIVERED");
      const index = state.messages.findIndex((i: any) => i.id == data.id);
      if (index > -1) {
        state.messages[index].delivered_at = data.delivered_at;
        state.messages[index].updated_at = new Date().toISOString();
      }
    },
    RESET_MESSAGES: (state: any) => {
      state.messages = {};
    }
  },
  actions: {
    GET_MESSAGES: ({ commit }: { commit: any }, data: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .get(`message/${data.chat}?page=${data.page}`)
          .then(({ data }) => {
            commit("SET_MESSAGES", data.data);
            resolve(true);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    CREATE_MESSAGE: ({ commit }: { commit: any }, data: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .post(`message`, data)
          .then(({ data }) => {
            commit("APPEND_MESSAGE", data.data);
            commit("chat/SET_MESSAGE_TO_CHAT", data.data, { root: true });
            resolve(true);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    RESET_MESSAGES: ({ commit }: { commit: any }) => {
      commit("RESET_MESSAGES");
    }
  }
};
