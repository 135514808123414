type Props = {
  colors: {
    enable_color_customization?: string;
    header?: string;
    footer?: string;
    headerText?: string;
    footerText?: string;
    primary?: string;
    darkPrimary?: string;
    secondary?: string;
    text1?: string;
    text2?: string;
    text3?: string;
    text4?: string;
    text5?: string;
  };
};

export const updateThemeColors = (colors: Props["colors"]) => {
  if (colors.enable_color_customization !== "1") return;

  document.body.classList.add("over-ride");
  const root = document.documentElement;
  Object.keys(colors).forEach(key => {
    if (key === "enable_color_customization") {
      return;
    }
    if (colors[key as keyof Props["colors"]]) {
      root.style.setProperty(`--${key}`, colors[key as never]);
    }
  });
};
