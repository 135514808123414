import Api from "@/services/Api";

export default {
  namespaced: true,
  state: {
    program: {},
    programs: [],
    overview: {},
    ratings: {},
    certificate: {}
  },
  getters: {
    getPrograms: (state: any) => state.programs,
    getProgramRatings: (state: any) => state.ratings,
    getProgram: (state: any) => state.program,
    getCertificate: (state: any) => state.certificate
  },
  mutations: {
    SET_PROGRAMS: (state: any, data: any) => {
      state.programs = data;
    },
    SET_PROGRAM_SEATS: (state: any, data: any) => {
      state.seats = data;
    },
    SET_PROGRAM_OVERVIEW: (state: any, data: any) => {
      state.overview = data;
    },
    SET_PROGRAM: (state: any, data: any) => {
      state.program = data;
    },
    SET_PROGRAM_RATINGS: (state: any, data: any) => {
      state.ratings = data;
    },
    SET_PROGRAM_CERTIFICATE: (state: any, data: any) => {
      state.certificate = data;
    },
    SET_PROGRAM_PARTICIPANTS: (state: any, data: any) => {
      state.programParticipants = data;
    },
    UPDATE_PROGRAM_COUNT: (state: any, count = 0) => {
      state.program.comments_count = count;
    }
  },
  actions: {
    GET_ALL_PROGRAM: (
      { commit }: { dispatch: any; commit: any },
      data = { page: 1, data: {} }
    ) => {
      return new Promise((resolve, reject) => {
        Api()
          .post(`program/filter?page=${data.page}`, data.data)
          .then(({ data }) => {
            commit("SET_PROGRAMS", data.data);
            resolve(data.data);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    GET_ALL_PROGRAM_REQUESTS: (
      { commit }: { dispatch: any; commit: any },
      data = { page: 1, data: {} }
    ) => {
      return new Promise((resolve, reject) => {
        Api()
          .post(`program/requests?page=${data.page}`, data.data)
          .then(({ data }) => {
            commit("SET_PROGRAMS", data.data);
            resolve(data.data);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    CREATE_PROGRAM: (_: any, data: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .post("program", data)
          .then(({ data }) => {
            resolve(data.data.id);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    CREATE_PROGRAM_REQUEST: (_: any, data: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .post("program/request", data)
          .then(({ data }) => {
            resolve(data.data);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    UPDATE_PROGRAM: (_: any, data: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .put(`program/${data.id}`, data.formData)
          .then(() => {
            resolve(data.id);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    GET_PROGRAM: ({ commit }: { dispatch: any; commit: any }, id: string) => {
      return new Promise((resolve, reject) => {
        Api()
          .get(`program/${id}`)
          .then(({ data }) => {
            commit("SET_PROGRAM", data.data);
            resolve(data.data);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    DECLINE_PROGRAM: (_: any, id: number) => {
      return new Promise((resolve, reject) => {
        Api()
          .post(`program/${id}/status`, { status: "cancelled" })
          .then(({ data }) => {
            resolve(data.data);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    GET_USER_FROM_EMAIL: (_: any, email: string) => {
      return new Promise((resolve, reject) => {
        Api()
          .post(`program/mentee`, { email })
          .then(({ data }) => {
            resolve(data.data);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    POST_INVITATION_PASSWORD: (_: any, data: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .post(`program/${data.id}/invitation_password`, data)
          .then(({ data }) => {
            resolve(data.data);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    GET_PROGRAM_SEATS: (
      { commit }: { dispatch: any; commit: any },
      data: any
    ) => {
      return new Promise((resolve, reject) => {
        Api()
          .get(
            `program/${data.id}/seat?all_seats=${data.all_seats}&page=${data.page}`
          )
          .then(({ data }) => {
            commit("SET_PROGRAM_SEATS", data);
            resolve(data);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    GET_PROGRAM_OVERVIEW: (
      { commit }: { dispatch: any; commit: any },
      id: any
    ) => {
      return new Promise((resolve, reject) => {
        Api()
          .get(`program/${id}/overview`)
          .then(({ data }) => {
            commit("SET_PROGRAM_OVERVIEW", data.data);
            resolve(true);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    GET_RATINGS: ({ commit }: { commit: any }, id: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .get(`program/${id}/rating`)
          .then(({ data }) => {
            commit("SET_PROGRAM_RATINGS", data.data);
            resolve(true);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    GIVE_RATING: (_: any, data: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .post(`program/${data.id}/rating`, data.data)
          .then(() => {
            resolve(true);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    GENERATE_ICS: (_: any, id: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .post(`program/${id}/ics`)
          .then(({ data }) => {
            resolve(data.data);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    ADD_CERTIFICATES: ({ dispatch }: { dispatch: any }, payload: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .post(`program/${payload.programId}/certificate`, payload.data)
          .then(({ data }) => {
            resolve(data.data);
            dispatch("GET_CERTIFICATES");
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    GET_CERTIFICATES: (_: any, data: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .get(`program/${data.id}/certificate?page=${data.page}`)
          .then(({ data }) => {
            resolve(data.data);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    GET_CERTIFICATE: ({ commit }: { commit: any }, data: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .get(`program/${data.id}/certificate/${data.certificateId}`)
          .then(({ data }) => {
            commit("SET_PROGRAM_CERTIFICATE", data.data);
            resolve(data.data);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    DOWNLOAD_CERTIFICATE: (_: any, id: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .post(`program/${id}/certificate`, null, { responseType: "blob" })
          .then(({ data }) => {
            resolve(data);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    FINANCIAL_OVERVIEW: (_: any, data: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .post("program/financial_overview", data)
          .then(({ data }) => {
            resolve(data.data);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    ACCEPT_ZERO_PROGRAM_LICENCE: (_: any, data: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .post("program/zero_licence", data)
          .then(() => {
            resolve(true);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    GET_PROGRAM_PARTICIPANTS: (
      { commit }: { dispatch: any; commit: any },
      body: any
    ) => {
      return new Promise((resolve, reject) => {
        const qs = Object.keys(body.data)
          .map(
            key =>
              `${encodeURIComponent(key)}=${encodeURIComponent(body.data[key])}`
          )
          .join("&");
        console.log(qs);
        Api()
          .get(`program/${body.id}/participant?${qs}`)
          .then(({ data }) => {
            commit("SET_PROGRAM_PARTICIPANTS", data.data);
            resolve(data.data);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    GENERATE_PROGRAM_PARTICIPANTS: (_: any, id: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .post(`program/${id}/participant/export`, null, {
            responseType: "blob"
          })
          .then(({ data }) => {
            resolve(data);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    CONCLUDE_PROGRAM: (_: any, id: any) => {
      return new Promise((resolve, reject) => {
        console.log("here");

        Api()
          .post(`program/${id}/conclude`)
          .then(() => {
            resolve(true);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    GET_PROGRAM_GALLERY: () => {
      return new Promise((resolve, reject) => {
        Api()
          .get(`program/gallery`)
          .then(({ data }) => {
            resolve(data.data);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    SET_PROGRAM_COMMENTS_COUNT: (
      { commit }: { dispatch: any; commit: any },
      id: any
    ) => {
      return new Promise((resolve, reject) => {
        Api()
          .post(`program/${id}/comment/update`)
          .then(() => {
            commit("UPDATE_PROGRAM_COUNT");
            resolve(true);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    GET_PROGRAM_COMMENTS_COUNT: (
      { commit }: { dispatch: any; commit: any },
      id: any
    ) => {
      return new Promise((resolve, reject) => {
        Api()
          .get(`program/${id}/comment`)
          .then(({ data }) => {
            commit("UPDATE_PROGRAM_COUNT", data.data);
            resolve(true);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    GET_CHAT: (_: any, id: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .get(`program/${id}/chat`)
          .then(({ data }) => {
            resolve(data.data);
          })
          .catch(e => {
            reject(e);
          });
      });
    },

    // content
    GET_ALL_PROGRAM_CONTENT: (_: any, data: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .get(
            `program/${data.program_id}/content?page=${data.page}`,
            data.data
          )
          .then(({ data }) => {
            resolve(data);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    GET_PROGRAM_CONTENT: (_: any, data: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .get(`program/${data.program_id}/content/${data.content_id}`)
          .then(({ data }) => {
            resolve(data.data);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    CREATE_PROGRAM_CONTENT: (_: any, data: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .post(`program/${data.program_id}/content`, data.data)
          .then(({ data }) => {
            resolve(data.data);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    UPDATE_PROGRAM_CONTENT: (_: any, data: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .post(
            `program/${data.program_id}/content/${data.content_id}`,
            data.data
          )
          .then(({ data }) => {
            resolve(data.data);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    DELETE_PROGRAM_CONTENT: (_: any, data: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .delete(`program/${data.program_id}/content/${data.content_id}`)
          .then(({ data }) => {
            resolve(data.data);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    DOWNLOAD_PROGRAM_CONTENT: (_: any, data: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .get(`program/${data.program_id}/content/${data.content_id}/download`)
          .then(({ data }) => {
            resolve(data.data);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    REFUND_PROGRAM: (_: any, data: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .post(`program/refund/${data.id}/request`, { reason: data.reason })
          .then(({ data }) => {
            resolve(data.data);
          })
          .catch(e => {
            reject(e);
          });
      });
    }
  }
};
