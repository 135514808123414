import Vue from "vue";
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import InlineSvg from "vue-inline-svg";
import VueSlider from "vue-slider-component";
import VSelect from "vue-select";
import "vue-slider-component/theme/default.css";
import "vue-simple-range-slider/dist/vueSimpleRangeSlider.css";
import "vue-select/dist/vue-select.css";
import VueCircle from "vue2-circle-progress";
import App from "./App.vue";
import router from "./router";
import axios from "axios";
import VueAxios from "vue-axios";
import store from "./store";
// @ts-ignore
import VueSocialauth from "vue-social-auth";
// @ts-ignore
import VueAlertify from "vue-alertify";
//@ts-ignore
import VueImg from "v-img";
import Skeleton from "vue-loading-skeleton";
// @ts-ignore
import VueTheMask from "vue-the-mask";
import VueRx from "vue-rx";
// Use build files
import VuejsClipper from "vuejs-clipper";
import VueMeta from "vue-meta";
// @ts-ignore
import JsonCSV from "vue-json-csv";
// @ts-ignore
import JsonExcel from "vue-json-excel";
// @ts-ignore
import { EmojiPickerPlugin } from "vue-emoji-picker";
import VueSocialSharing from "vue-social-sharing";
// @ts-ignore
import VueYoutube from "vue-youtube";
import LoadMore from "@/components/Partials/LoadMore.vue";
// @ts-ignore
Vue.use(VuejsClipper);

Vue.use(VueMeta);

Vue.component("downloadCsv", JsonCSV);

Vue.component("downloadExcel", JsonExcel);

Vue.use(EmojiPickerPlugin);

Vue.use(VueSocialSharing);

Vue.use(VueYoutube);

Vue.use(VueRx);
Vue.use(VueTheMask);

Vue.component("inline-svg", InlineSvg);
Vue.component("vue-slider", VueSlider);
Vue.component("v-select", VSelect);
Vue.component("vue-circle", VueCircle);
// Vue.component('skeleton', Skeleton);
Vue.use(Skeleton);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(VueAxios, axios);
Vue.use(VueAlertify);
// @ts-ignore
import i18n from "./i18n";

Vue.use(VueSocialauth, {
  providers: {
    facebook: {
      clientId: process.env.VUE_APP_FACEBOOK_ID,
      redirectUri: process.env.VUE_APP_FACEBOOK_CALLBACK
    },
    linkedin: {
      requiredUrlParams: ["scope"],
      scope: ["r_liteprofile", "r_emailaddress"],
      clientId: process.env.VUE_APP_LINKEDIN_ID,
      redirectUri: process.env.VUE_APP_LINKEDIN_CALLBACK
    },
    google: {
      clientId: process.env.VUE_APP_GOOGLE_ID,
      redirectUri: process.env.VUE_APP_GOOGLE_CALLBACK
    }
  }
});

Vue.use(VueImg, {
  altAsTitle: false,
  sourceButton: false,
  openOn: "click",
  thumbnails: false
});

//@ts-ignore
String.prototype.toCapitalize = function() {
  const string = Array.from(this);
  if (string.length) {
    string[0] = string[0].toUpperCase();
  }
  return string.join("");
};

// For more options see below updated
Vue.config.productionTip = false;
Vue.component("load-more", LoadMore);

new Vue({
  // @ts-ignore
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount("#app");
