import Vue from "vue";
// @ts-ignore
import multiguard from "vue-router-multiguard";
import auth from "@/middleware/auth";
import userType from "@/middleware/userType";
import guest from "@/middleware/guest";
import VueRouter, { RouteConfig } from "vue-router";
import checkProfile from "@/middleware/checkProfile";
import TranslationHelper from "@/helpers/translation";
import isFeatureEnabeld from "@/middleware/isFeatureEnabeld";
import canAccessProgram from "@/middleware/canAccessProgram";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "LandingPage",
    component: () =>
      import(/* webpackChunkName: "LandingPage" */ "../views/LandingPage.vue"),
    beforeEnter: guest,
    meta: {
      layout: "DefaultLayout"
    }
  },
  {
    path: "/iframe",
    name: "Iframe",
    component: () =>
      import(/* webpackChunkName: "Iframe" */ "../views/Iframe.vue"),
    meta: {
      layout: "DefaultLayout"
    }
  },
  {
    path: "/auth/:provider/callback",
    component: {
      template: '<div class="auth-component"></div>'
    },
    meta: {
      layout: "DefaultLayout"
    }
  },
  {
    path: "/register/profile-fill",
    name: "SignUpProfileFillPage",
    component: () =>
      import(
        /* webpackChunkName: "SignUpProfileFillPage" */ "@/views/SignUp/SignUpProfileFillPage.vue"
      ),
    beforeEnter: auth,
    meta: {
      layout: "DefaultLayout"
    }
  },
  {
    path: "/register/profile",
    name: "SignUpProfilePage",
    component: () =>
      import(
        /* webpackChunkName: "SignUpProfilePage" */ "@/views/SignUp/SignUpProfilePage.vue"
      ),
    beforeEnter: auth,
    meta: {
      layout: "DefaultLayout"
    }
  },
  {
    path: "/register/information",
    name: "SignUpUserInformationPage",
    component: () =>
      import(
        /* webpackChunkName: "SignUpUserInformationPage" */ "@/views/SignUp/SignUpUserInformationPage.vue"
      ),
    beforeEnter: multiguard([auth, userType("mentor")]),
    meta: {
      layout: "DefaultLayout"
    }
  },
  {
    path: "/register/privacy",
    name: "SignUpPrivacyPage",
    component: () =>
      import(
        /* webpackChunkName: "SignUpPrivacyPage" */ "@/views/SignUp/SignUpPrivacyPage.vue"
      ),
    beforeEnter: auth,
    meta: {
      layout: "DefaultLayout"
    }
  },
  {
    path: "/register/mentor/:step?",
    name: "SignUpPageMentor",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "SignUpPageMentor" */ "@/views/SignUp/SignUpPageMentor.vue"
      ),
    beforeEnter: guest,
    meta: {
      layout: "DefaultLayout"
    }
  },
  {
    path: "/register/:step?",
    name: "SignUpPageMentoree",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "SignUpPageMentoree" */ "@/views/SignUp/SignUpPageMentoree.vue"
      ),
    beforeEnter: guest,
    meta: {
      layout: "DefaultLayout"
    }
  },
  {
    path: "/profile",
    name: "ProfilePage",
    component: () =>
      import(/* webpackChunkName: "ProfilePage" */ "../views/ProfilePage.vue"),
    beforeEnter: auth,
    meta: {
      layout: "DefaultLayout"
    }
  },
  {
    path: "/statistics",
    name: "StatisticsPage",
    component: () =>
      import(
        /* webpackChunkName: "StatisticsPage" */ "@/views/StatisticsPage.vue"
      ),
    beforeEnter: auth,
    meta: {
      layout: "DefaultLayout"
    }
  },
  {
    path: "/ratings",
    name: "RatingsPage",
    component: () =>
      import(
        /* webpackChunkName: "RatingsPage" */ "@/views/Mentor/RatingsPage.vue"
      ),
    beforeEnter: auth,
    meta: {
      layout: "DefaultLayout"
    }
  },
  {
    path: "/news-feed",
    name: "NewsFeedPage",
    component: () =>
      import(/* webpackChunkName: "NewsFeedPage" */ "@/views/NewsFeedPage.vue"),
    beforeEnter: auth,
    meta: {
      layout: "DefaultLayout"
    }
  },
  {
    path: "/community",
    name: "CommunityPage",
    component: () =>
      import(
        /* webpackChunkName: "CommunityPage" */ "@/views/CommunityPage.vue"
      ),
    beforeEnter: multiguard([
      auth,
      userType("mentor"),
      isFeatureEnabeld("mentor_community")
    ]),
    meta: {
      layout: "DefaultLayout"
    }
  },
  {
    path: "/mentorcasts",
    name: "MentorcastsPage",
    component: () =>
      import(
        /* webpackChunkName: "MentorcastsPage" */ "@/views/MentorcastsPage.vue"
      ),
    beforeEnter: multiguard([auth, isFeatureEnabeld("mentorcast")]),
    meta: {
      layout: "DefaultLayout"
    }
  },
  // {
  //   path: "/mentorcasts/available/:categoryId?",
  //   name: "AvailableMentorcastsPage",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "AvailableMentorcastsPage" */ "@/views/AvailableMentorcastsPage.vue"
  //     ),
  //   props: true,
  //   beforeEnter: auth,
  //   meta: {
  //   layout: 'DefaultLayout'
  // }
  // },
  {
    path: "/mentorcasts/create",
    name: "CreateMentorcastPage",
    component: () =>
      import(
        /* webpackChunkName: "CreateMentorcastPage" */ "@/views/Mentor/CreateMentorcastPage.vue"
      ),
    beforeEnter: multiguard([
      auth,
      userType("mentor"),
      isFeatureEnabeld("mentorcast")
    ]),
    meta: {
      layout: "DefaultLayout"
    }
  },
  {
    path: "/mentorcasts/:id/edit",
    name: "EditMentorcastPage",
    component: () =>
      import(
        /* webpackChunkName: "EditMentorcastPage" */ "@/views/Mentor/EditMentorcastPage.vue"
      ),
    beforeEnter: multiguard([auth, isFeatureEnabeld("mentorcast")]),
    meta: {
      layout: "DefaultLayout"
    }
  },
  {
    path: "/courses/create",
    name: "CreateCoursePage",
    component: () =>
      import(
        /* webpackChunkName: "CreateCoursePage" */ "@/views/Mentor/CreateCoursePage.vue"
      ),
    beforeEnter: multiguard([
      auth,
      userType("mentor")
      // isFeatureEnabeld("mentorcast")
    ]),
    meta: {
      layout: "DefaultLayout"
    }
  },
  {
    path: "/courses/:id/edit",
    name: "EditCoursePage",
    component: () =>
      import(
        /* webpackChunkName: "EditCoursePage" */ "@/views/Mentor/EditCoursePage.vue"
      ),
    beforeEnter: multiguard([auth]),
    meta: {
      layout: "DefaultLayout"
    }
  },
  {
    path: "/posts/:id",
    name: "PostDetailsPage",
    component: () =>
      import(
        /* webpackChunkName: "PostDetailsPage" */ "@/views/PostDetailsPage.vue"
      ),
    meta: {
      layout: "DefaultLayout"
    }
  },
  {
    path: "/mentorcasts/:id",
    name: "HeroPage",
    component: () =>
      import(
        /* webpackChunkName: "HeroPage" */ "@/views/Mentoree/HeroPage.vue"
      ),
    beforeEnter: multiguard([isFeatureEnabeld("mentorcast")]),
    meta: {
      layout: "DefaultLayout"
    }
  },
  {
    path: "/mentorcasts/:id/join",
    name: "MentorcastJoinPage",
    component: () =>
      import(
        /* webpackChunkName: "MentorcastJoinPage" */ "@/views/Mentoree/MentorcastJoinPage.vue"
      ),
    beforeEnter: multiguard([auth, isFeatureEnabeld("mentorcast")]),
    meta: {
      layout: "DefaultLayout"
    }
  },
  {
    path: "/mentorcasts/:id/rating",
    name: "MentorcastRatingsPage",
    component: () =>
      import(
        /* webpackChunkName: "MentorcastRatingsPage" */ "@/views/Mentoree/MentorcastRatingsPage.vue"
      ),
    beforeEnter: multiguard([auth, isFeatureEnabeld("mentorcast")]),
    meta: {
      layout: "DefaultLayout"
    }
  },
  {
    path: "/mentorcasts/:id/discussion",
    name: "MentorcastDiscussionPage",
    component: () =>
      import(
        /* webpackChunkName: "MentorcastDiscussionPage" */ "@/views/MentorcastDiscussionPage.vue"
      ),
    beforeEnter: multiguard([isFeatureEnabeld("mentorcast")]),
    meta: {
      layout: "DefaultLayout"
    }
  },
  {
    path: "/mentorcasts/:id/overview",
    name: "FinishedMentorcastPage",
    component: () =>
      import(
        /* webpackChunkName: "FinishedMentorcastPage" */ "@/views/Mentor/FinishedMentorcastPage.vue"
      ),
    beforeEnter: multiguard([
      auth,
      userType("mentor"),
      isFeatureEnabeld("mentorcast")
    ]),
    meta: {
      layout: "DefaultLayout"
    }
  },
  {
    path: "/mentorcasts/:id/certificate",
    name: "MentorcastCertificatePage",
    component: () =>
      import(
        /* webpackChunkName: "FinishedMentorcastPage" */ "@/views/MentorcastCertificatePage.vue"
      ),
    beforeEnter: multiguard([isFeatureEnabeld("mentorcast")]),
    meta: {
      layout: "DefaultLayout"
    }
  },
  {
    path: "/search",
    name: "SearchMentorPage",
    component: () =>
      import(
        /* webpackChunkName: "SearchMentorPage" */ "@/views/Mentoree/SearchMentor/SearchMentorPage.vue"
      ),
    beforeEnter: auth,
    meta: {
      layout: "DefaultLayout"
    }
  },
  {
    path: "/search/results",
    name: "SearchMentorResultsPage",
    component: () =>
      import(
        /* webpackChunkName: "SearchMentorResultsPage" */ "@/views/Mentoree/SearchMentor/SearchMentorResultsPage.vue"
      ),
    beforeEnter: auth,
    meta: {
      layout: "DefaultLayout"
    }
  },
  {
    path: "/sessions",
    name: "SessionPage",
    component: () =>
      import(/* webpackChunkName: "SessionsPage" */ "@/views/SessionsPage.vue"),
    beforeEnter: multiguard([auth, isFeatureEnabeld("mentoring_session")]),
    meta: {
      layout: "DefaultLayout"
    }
  },
  {
    path: "/sessions/:id",
    name: "MentoringSessionPage",
    component: () =>
      import(
        /* webpackChunkName: "MentoringSessionPage" */ "@/views/MentoringSessionPage.vue"
      ),
    beforeEnter: multiguard([auth, isFeatureEnabeld("mentoring_session")]),
    meta: {
      layout: "DefaultLayout"
    }
  },
  {
    path: "/sessions/:id/certificate",
    name: "SessionCertificatePage",
    component: () =>
      import(
        /* webpackChunkName: "MentoringSessionPage" */ "@/views/SessionCertificatePage.vue"
      ),
    beforeEnter: multiguard([isFeatureEnabeld("mentoring_session")]),
    meta: {
      layout: "DefaultLayout"
    }
  },
  {
    path: "/sessions/:id/feedback",
    name: "MentoringSessionFeedbackPage",
    component: () =>
      import(
        /* webpackChunkName: "MentoringSessionFeedbackPage" */ "@/views/MentoringSessionFeedbackPage.vue"
      ),
    beforeEnter: multiguard([auth, isFeatureEnabeld("mentoring_session")]),
    meta: {
      layout: "DefaultLayout"
    }
  },
  {
    path: "/mentoring-programs",
    name: "MentoringProgramsPage",
    component: () =>
      import(
        /* webpackChunkName: "MentoringPlanPage" */ "@/views/MentoringProgramsPage.vue"
      ),
    beforeEnter: multiguard([auth, isFeatureEnabeld("mentoring_program")]),
    meta: {
      layout: "DefaultLayout"
    }
  },
  {
    path: "/mentoring-program/:id/rating",
    name: "MentoringPlanRatingPage",
    component: () =>
      import(
        /* webpackChunkName: "MentoringPlanPage" */ "@/views/MentoringPlanRatingPage.vue"
      ),
    beforeEnter: multiguard([auth, isFeatureEnabeld("mentoring_program")]),
    meta: {
      layout: "DefaultLayout"
    }
  },
  {
    path: "/mentoring-program/:id/certificate/:certificateId",
    name: "MentoringPlanCertificatePage",
    component: () =>
      import(
        /* webpackChunkName: "MentoringPlanPage" */ "@/views/MentoringProgramCertificatePage.vue"
      ),
    beforeEnter: multiguard([auth, isFeatureEnabeld("mentoring_program")]),
    meta: {
      layout: "DefaultLayout"
    }
  },
  {
    path: "/mentoring-programs/create",
    name: "CreateMentoringProgramPage",
    component: () =>
      import(
        /* webpackChunkName: "CreateMentoringProgramPage" */ "@/views/CreateMentoringProgramPage.vue"
      ),
    beforeEnter: multiguard([auth, isFeatureEnabeld("mentoring_program")]),
    meta: {
      layout: "DefaultLayout"
    }
  },
  {
    path: "/mentoring-programs/:id/edit",
    name: "EditMentorcastPage",
    component: () =>
      import(
        /* webpackChunkName: "EditMentorcastPage" */ "@/views/EditMentoringProgramPage.vue"
      ),
    beforeEnter: multiguard([auth, isFeatureEnabeld("mentoring_program")]),
    meta: {
      layout: "DefaultLayout"
    }
  },
  {
    path: "/mentoring-programs/:id",
    name: "MentoringPlanPage",
    component: () =>
      import(
        /* webpackChunkName: "MentoringPlanPage" */ "@/views/Mentor/MentoringPlanPage.vue"
      ),
    beforeEnter: multiguard([
      auth,
      isFeatureEnabeld("mentoring_program"),
      canAccessProgram
    ]),
    meta: {
      layout: "DefaultLayout"
    }
  },
  {
    path: "/my-library",
    name: "MyLibraryPage",
    component: () =>
      import(
        /* webpackChunkName: "MyLibraryPage" */ "@/views/Mentor/MentorLibrary.vue"
      ),
    beforeEnter: multiguard([auth, isFeatureEnabeld("mentor_library")]),
    meta: {
      layout: "DefaultLayout"
    }
  },
  {
    path: "/library-content/:id",
    name: "LibraryItemPage",
    component: () =>
      import(
        /* webpackChunkName: "LibraryItemPage" */ "@/views/Mentor/LibraryItemPage.vue"
      ),
    beforeEnter: multiguard([auth, isFeatureEnabeld("mentor_library")]),
    meta: {
      layout: "DefaultLayout"
    }
  },
  {
    path: "/my-certificate",
    name: "MenteeCertifcatePage",
    component: () => import("@/views/Mentoree/CertificatePage.vue"),
    beforeEnter: multiguard([auth /*, userType("mentee")*/]),
    meta: {
      layout: "DefaultLayout"
    }
  },
  {
    path: "/settings",
    name: "AdminPanelPage",
    component: () =>
      import(
        /* webpackChunkName: "AdminPanelPage" */ "@/views/AdminPanelPage.vue"
      ),
    beforeEnter: auth,
    meta: {
      layout: "DefaultLayout"
    }
  },
  {
    path: "/agenda",
    name: "AgendaPage",
    component: () =>
      import(/* webpackChunkName: "AgendaPage" */ "@/views/AgendaPage.vue"),
    props: true,
    beforeEnter: auth,
    meta: {
      layout: "DefaultLayout"
    }
  },
  {
    path: "/conversations/:id?",
    name: "ConversationsPage",
    component: () =>
      import(
        /* webpackChunkName: "ConversationsPage" */ "@/views/ConversationsPage.vue"
      ),
    props: true,
    beforeEnter: auth,
    meta: {
      layout: "DefaultLayout"
    }
  },
  {
    path: "/wallet",
    name: "WalletPage",
    component: () =>
      import(/* webpackChunkName: "WalletPage" */ "@/views/WalletPage.vue"),
    beforeEnter: auth,
    meta: {
      layout: "DefaultLayout"
    }
  },
  {
    path: "/mentors/:id",
    name: "MentorProfilePage",
    component: () =>
      import(
        /* webpackChunkName: "MentorProfilePage" */ "@/views/Mentor/MentorProfilePage.vue"
      ),
    beforeEnter: multiguard([auth, checkProfile()]),
    meta: {
      layout: "DefaultLayout"
    }
  },
  {
    path: "/mentors/:id/report",
    name: "ReportMentorPage",
    component: () =>
      import(
        /* webpackChunkName: "ReportMentorPage" */ "@/views/Mentoree/ReportMentorPage.vue"
      ),
    beforeEnter: auth,
    meta: {
      layout: "DefaultLayout"
    }
  },
  {
    path: "/mentors/:id/library",
    name: "LibraryMentorPage",
    component: () =>
      import(
        /* webpackChunkName: "LibraryMentorPage" */ "@/views/Mentoree/LibraryPage.vue"
      ),
    beforeEnter: auth,
    meta: {
      layout: "DefaultLayout"
    }
  },
  {
    path: "/mentors/:id/agenda",
    name: "MentorAgendaPage",
    component: () =>
      import(
        /* webpackChunkName: "MentorAgendaPage" */ "@/views/Mentor/MentorAgendaPage.vue"
      ),
    beforeEnter: multiguard([auth, checkProfile("agenda")]),
    meta: {
      layout: "DefaultLayout"
    }
  },
  {
    path: "/mentors/:id/statistics",
    name: "MentorStatisticsPage",
    component: () =>
      import(
        /* webpackChunkName: "MentorStatisticsPage" */ "@/views/Mentor/MentorStatisticsPage.vue"
      ),
    beforeEnter: multiguard([auth, checkProfile("statistics")]),
    meta: {
      layout: "DefaultLayout"
    }
  },
  {
    path: "/mentors/:id/ratings",
    name: "MentorRatingsPage",
    component: () =>
      import(
        /* webpackChunkName: "MentorRatingsPage" */ "@/views/Mentor/MentorRatingsPage.vue"
      ),
    beforeEnter: auth,
    meta: {
      layout: "DefaultLayout"
    }
  },
  {
    path: "/mentors/:id/mentorcasts",
    name: "MentorMentorcastsPage",
    component: () =>
      import(
        /* webpackChunkName: "MentorMentorcastsPage" */ "@/views/Mentor/MentorMentorcastsPage.vue"
      ),
    beforeEnter: multiguard([
      auth,
      checkProfile("mentorcasts"),
      isFeatureEnabeld("mentorcast")
    ]),
    meta: {
      layout: "DefaultLayout"
    }
  },
  {
    path: "/mentors/:id/community",
    name: "MentorCommunityPage",
    component: () =>
      import(
        /* webpackChunkName: "CommunityPage" */ "@/views/CommunityPage.vue"
      ),
    beforeEnter: multiguard([
      auth,
      checkProfile("community") /*isFeatureEnabeld('mentor_community')*/
    ]),
    meta: {
      layout: "DefaultLayout"
    }
  },
  {
    path: "/mentorees/:id",
    name: "MentoreeProfilePage",
    component: () =>
      import(
        /* webpackChunkName: "MentoreeProfilePage" */ "@/views/Mentoree/MentoreeProfilePage.vue"
      ),
    beforeEnter: multiguard([auth, checkProfile()]),
    meta: {
      layout: "DefaultLayout"
    }
  },
  {
    path: "/mentorees/:id/statistics",
    name: "MentoreeStatisticsPage",
    component: () =>
      import(
        /* webpackChunkName: "MentoreeStatisticsPage" */ "@/views/Mentoree/MentoreeStatisticsPage.vue"
      ),
    beforeEnter: multiguard([auth, checkProfile("statistics")]),
    meta: {
      layout: "DefaultLayout"
    }
  },
  {
    path: "/discover/mentors",
    name: "DiscoverMentorsPage",
    component: () =>
      import(
        /* webpackChunkName: "DiscoverMentorsPage" */ "@/views/DiscoverMentorsPage.vue"
      ),
    meta: {
      layout: "DefaultLayout"
    }
  },
  {
    path: "/discover/mentorcasts",
    name: "DiscoverMentorcastsPage",
    component: () =>
      import(
        /* webpackChunkName: "DiscoverMentorcastsPage" */ "@/views/DiscoverMentorcastsPage.vue"
      ),
    beforeEnter: multiguard([isFeatureEnabeld("mentorcast")]),
    meta: {
      layout: "DefaultLayout"
    }
  },
  {
    path: "/discover/people",
    name: "DiscoverPeoplePage",
    component: () =>
      import(
        /* webpackChunkName: "DiscoverMentorcastsPage" */ "@/views/DiscoverPeoplePage.vue"
      ),
    meta: {
      layout: "DefaultLayout"
    }
  },
  {
    path: "/forgot-password/:step?",
    name: "ForgotPassword,",
    component: () =>
      import(
        /* webpackChunkName: "ForgotPasswordPage" */ "@/views/ForgotPasswordPage.vue"
      ),
    meta: {
      layout: "DefaultLayout"
    }
  },
  {
    path: "/page/:slug",
    name: "DynamicPage",
    component: () =>
      import(/* webpackChunkName: "DynamicPage" */ "@/views/DynamicPage.vue"),
    meta: {
      layout: "DefaultLayout"
    }
  },
  {
    path: "/contact-us",
    name: "ContactUsPage",
    component: () =>
      import(
        /* webpackChunkName: "ContactUsPage" */ "@/views/ContactUsPage.vue"
      ),
    meta: {
      layout: "DefaultLayout"
    }
  },
  {
    path: "/500",
    name: "500",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Errors/500Page.vue"),
    meta: {
      layout: "DefaultLayout"
    }
  },
  {
    path: "/404",
    name: "404",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Errors/404Page.vue"),
    meta: {
      layout: "DefaultLayout"
    }
  },
  {
    path: "/408",
    name: "408",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Errors/408Page.vue"),
    meta: {
      layout: "DefaultLayout"
    }
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is  lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
    beforeEnter: auth,
    meta: {
      layout: "DefaultLayout"
    }
  },
  {
    path: "*",
    name: "404",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Errors/404Page.vue"),
    meta: {
      layout: "DefaultLayout"
    }
  }
];

const router = new VueRouter({
  mode: "history",
  // mode: "hash",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach(async (to, from, next) => {
  if (localStorage.getItem("isGuest")) {
    localStorage.removeItem("isGuest");
    localStorage.removeItem("token");
    localStorage.removeItem("logged");
  }

  window.scrollTo(0, 0);

  try {
    const selectedLang = localStorage.getItem("language");
    if (selectedLang) {
      await TranslationHelper.updateTranslations(selectedLang, true);
    } else {
      await TranslationHelper.updateTranslations();
    }
  } catch (e) {
    console.error(e);
  }
  return next();
});

// @ts-ignore
export default router;
