import Api from "@/services/Api";

export default {
  namespaced: true,
  state: {
    agenda: []
  },
  getters: {
    getAgenda: (state: any) => state.agenda
  },
  mutations: {
    SET_AGENDA: (state: any, data: any) => {
      state.agenda = data;
    },
    REMOVE_AGENDA: (state: any, data: any) => {
      if (data.date) {
        const index = state.agenda[data.date].findIndex(
          (i: any) => i.id == data.id
        );
        if (index > -1) {
          state.agenda[data.date].splice(index, 1);
        }
      } else {
        const index = state.agenda.findIndex((i: any) => i.id == data.id);
        if (index > -1) {
          state.agenda.splice(index, 1);
        }
      }
    }
  },
  actions: {
    GET_AGENDA: ({ commit }: { commit: any }, data: any) => {
      let url = `agenda?date=${data.date}&view=${data?.view}`;
      if (data.id) {
        url += `&id=${data.id}`;
      }
      return new Promise((resolve, reject) => {
        Api()
          .get(url)
          .then(({ data }) => {
            commit("SET_AGENDA", data.data);
            resolve(true);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    CREATE_AGENDA: (_: any, data: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .post(`agenda`, data)
          .then(() => {
            resolve(true);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    GET_DATE_TIMES: (_: any, date: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .get(`agenda/${date}/time`)
          .then(({ data }) => {
            resolve(data.data);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    CLOSE_AGENDA: ({ commit }: { commit: any }, data: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .delete(`agenda/${data.id}`)
          .then(() => {
            commit("REMOVE_AGENDA", data);
            resolve(true);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    GENERATE_ICS: (_: any, id: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .post(`agenda/${id}/ics`)
          .then(({ data }) => {
            resolve(data.data);
          })
          .catch(e => {
            reject(e);
          });
      });
    }
  }
};
