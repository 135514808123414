import Api from "@/services/Api";

export default {
  namespaced: true,
  state: {
    post: {},
    posts: []
  },
  getters: {
    getPosts: (state: any) => state.posts,
    getPost: (state: any) => state.post
  },
  mutations: {
    SET_POSTS: (state: any, data: any) => {
      state.posts = data;
    },
    SET_POST: (state: any, data: any) => {
      state.post = data;
    },
    RESET_POSTS: (state: any) => {
      state.posts = [];
    }
  },
  actions: {
    GET_ALL_POST: (
      { commit }: { dispatch: any; commit: any },
      data = { page: 1 }
    ) => {
      return new Promise((resolve, reject) => {
        Api()
          .get(`post?page=${data.page}`)
          .then(({ data }) => {
            commit("SET_POSTS", data.data);
            resolve(data.data);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    CREATE_POST: (_: any, data: any) => {
      return new Promise((resolve, reject) => {
        Api()
          .post("post", data)
          .then(({ data }) => {
            resolve(data.data);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    GET_POST: ({ commit }: { dispatch: any; commit: any }, id: string) => {
      return new Promise((resolve, reject) => {
        Api()
          .get(`post/${id}`)
          .then(({ data }) => {
            commit("SET_POST", data.data.post);
            resolve(data.data);
          })
          .catch(e => {
            reject(e);
          });
      });
    },

    RESET_POSTS: ({ commit }: { dispatch: any; commit: any }) => {
      commit("RESET_POSTS");
    },
    REMOVE_POST: (_: any, id: string) => {
      return new Promise((resolve, reject) => {
        Api()
          .delete(`post/${id}`)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(e => {
            reject(e);
          });
      });
    }
  }
};
